import React, { useEffect, useRef, useState } from "react";
//notification
import {
  notifyError,
  notifySuccess,
  notifyInfo,
} from "../../../Components/Notifications/Notifications";
//
//mail builder
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
//reach text editor
import ReactQuill from "react-quill";
//antdesign
import { Spin } from "antd";
import { Tooltip } from "antd";
import { Html } from "@mui/icons-material";

//Api END POINTS
import {
  addMailSync,
  getSingleMail,
  updateSingeleMailSync,
} from "../../../API/Mail_Sync/mailSyncAPI";
import { useLocation } from "react-router-dom";
interface JSONTemplate {
  counters: any;
  body: any;
}
const UpdateView = () => {
  //variables
  const [EditSubject, setEditSubject] = useState("");
  const [EditRecipients, setEditRecipients] = useState("");
  const [EditBodyHtml, setEditBodyHtml] = useState("");
  const [EditBodyJson, setEditBodyJson] = useState<JSONTemplate>();
  const [EditRecordNote, setEditRecordNote] = useState("");
  const [productData, setproductData] = useState([]);
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataLoading, setDataLoading] = React.useState<boolean>(true);
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);

  //### Model Methods
  const location = useLocation();
  //Routed RecordID
  const [routeRecordID, setRouteRecordID] = useState(
    location.state?.routeRecordId
  );
  //
  useEffect(() => {
    if (!routeRecordID && location.state?.routeRecordId) {
      const newRouteUserID = location.state.routeUserId;
      setRouteRecordID(newRouteUserID);
      // setEditUserID(newRouteUserID);
    }
  }, [location.state, routeRecordID]);
  useEffect(() => {
    const fetchData = async () => {
      if (routeRecordID) {
        await load_mutable_data(routeRecordID);
        // console.log("Attributes : ", attributes);
        setDataLoading(false);
      }
    };
    fetchData();
  }, []);
  //load data
  // Module Methods
  const load_mutable_data = async (id: Number) => {
    try {
      var req: any = await getSingleMail(id);
      const data = req.data || []; // Default to an empty array in case `call.data` is undefined
      // console.log(data);
      setproductData(data);
      // console.log(productData);

      // Wait for APIMainProductCategory to be populated before proceeding

      // Access the current state of categories and subcategories
      // Using the functional update to ensure you're getting the latest state

      // Find the edited product category and subcategory

      //

      // setProductID(data.id);
      setEditSubject(data.subject);
      setEditBodyHtml(data.bodyHtml);
      setEditBodyJson(data.bodyJson);
      // setEditRecipients("");
      // setEditRecordNote("");

      //auther
      if (data.Creater) {
        // setProductCreatedBy(data.Creater.fullName);
      }
      // setProductCreatedAt(data.createdAt);
      if (data.Updater) {
        // setProductUpdatedBy(data.Updater.fullName);
      }
      // setProductUpdatedAt(data.updatedAt);
      //

      //
      //console.log("ProductWarehouse:" + data.Product_Warehouses);
      //handleOpen();
      //
      //setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log("Error", error.message);
        notifyError("Error:" + error.message);
      }
      console.log(error);
      //throw error;
    } finally {
      // setDataLoading(true);
    }
    // alert(`CID=${EditProductCategorieID} \n CUUID=${EditProductCategoryUUID}`);
  };

  const HandleEdit = async (event: any) => {
    event.preventDefault();
    try {
      //
      setUpdateLoading(true);
      //

      const call = await updateSingeleMailSync({
        ID: routeRecordID,
        Subject: EditSubject,
        BodyHtml: EditBodyHtml,
        BodyJson: EditBodyJson,
        Record_Note: EditRecordNote,
      });
      console.log(call);
      //GetProducts();
      notifySuccess("");
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError("Error:" + error.message);
      }
      // console.log(err);
    } finally {
      //
      setUpdateLoading(false);
      //
    }
  };

  //email builder

  const emailEditorRef = useRef<EditorRef>(null);

  const onLoad: EmailEditorProps["onLoad"] = (unlayer) => {
    // if (EditBodyJson) {
    //   unlayer.loadDesign(EditBodyJson);
    // }
    // notifyInfo("Email editor OnLoad Know");
    // if (EditBodyJson && EditBodyJson !== undefined) {
    //   const longTextData: any = EditBodyJson;
    //   const jsonObject = JSON.parse(longTextData);
    //   unlayer.loadDesign(jsonObject);
    // }
  };
  //
  const onReady: EmailEditorProps["onReady"] = async (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    notifyInfo("Email editor Ready Know");

    if (EditBodyJson && EditBodyJson !== undefined) {
      // // Example LONGTEXT (utf8mb4_bin collation) from MySQL
      // let jsonObject = EditBodyJson;
      // // If EditBodyJson is a string, try to parse it
      // if (typeof EditBodyJson === "string") {
      //   notifyInfo("Data Is String");
      //   jsonObject = JSON.parse(EditBodyJson);
      // }

      // // // Load the design into unlayer
      // // notifyInfo("Data Is JSON");
      // // unlayer.loadDesign(jsonObject);
      // const longTextData: any = EditBodyJson;
      // // console.log(longTextData);
      // const longTextDataJson = JSON.stringify(longTextData);
      // // console.log(longTextDataJson);
      // const longTextDataJsonParse = JSON.parse(longTextDataJson);
      // console.log(longTextDataJsonParse);
      // const jsonObject = JSON.parse(longTextData);
      // console.log(jsonObject);
      // unlayer.loadDesign(jsonObject);

      // Delay the loading by 3 seconds
      notifyInfo("Before Delay");
      setTimeout(() => {
        const longTextData: any = EditBodyJson;
        const longTextDataJsonParse = JSON.parse(longTextData);
        console.log(longTextDataJsonParse);
        const jsonObject = JSON.parse(longTextData);
        unlayer.loadDesign(jsonObject); // Load the design after 3 seconds
        notifyInfo("in Delay");
      }, 3000); // 3 seconds delay
      notifyInfo("after Delay");
    }

    unlayer.addEventListener("design:updated", function (updates: any) {
      // Design has been updated by the user

      unlayer.exportHtml(function (data) {
        var json = data.design; // The updated design JSON
        var jsonString = JSON.stringify(json); // Convert object to JSON string
        const parsed = JSON.parse(jsonString); // Parse JSON string back to object
        var html = data.html; // The updated HTML
        setEditBodyHtml(html);
        setEditBodyJson(parsed);

        // Auto-save the JSON and/or HTML here
      });
    });
  };

  return (
    <div className="ModuleCreateView">
      <form className="ModuleCreateViewForm" onSubmit={HandleEdit}>
        <div className="form-container">
          <div className="form-header">
            <h4>Update Mailing Form</h4>
            <button className="moduleSaveBtn" type="submit">
              Update
            </button>
          </div>

          <div className="form-row">
            <label className="productlabels">
              Subject:
              <input
                type="text"
                placeholder="New Report"
                value={EditSubject}
                onChange={(event) => setEditSubject(event.target.value)}
                min={1}
                required
              />
            </label>
          </div>

          <div className="form-row">
            <label className="productlabels">
              Recipients
              <input
                type="text"
                placeholder="New Report"
                value={EditRecipients}
                onChange={(event) => setEditRecipients(event.target.value)}
                min={1}
                required
              />
            </label>
          </div>

          <div className="form-row">
            <label className="productlabels">
              Mail Body
              {/* <div>
                <button onClick={exportHtml}>Export HTML</button>
              </div> */}
              {/* <React.StrictMode> */}
              <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                onReady={onReady}
              />
              {/* </React.StrictMode> */}
            </label>
          </div>
          <div className="form-row">
            <ReactQuill theme="snow" onChange={setEditRecordNote} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateView;
