import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// API
import { getAllInventoryStatistics } from "../../../../../API/statistics/inventory_state_api";

ChartJS.register(ArcElement, Tooltip, Legend);

// Configurations
const options = {
  //new
  maintainAspectRatio: false,
  //
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Stock Types",
    },
  },
};

export function Stock_Type_Chart1() {
  const [chartData, setChartData] = useState({
    labels: ["Good", "Material", "Service", "Consumable"],
    datasets: [
      {
        label: "#No",
        data: [1, 1, 1, 1],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response: any = await getAllInventoryStatistics();
        console.log(response);
        const labels = response.data.StockType_Chart1.labels;
        const data = response.data.StockType_Chart1.data;

        setChartData((prevData) => ({
          ...prevData,
          labels,
          datasets: [
            {
              ...prevData.datasets[0],
              data,
            },
          ],
        }));
      } catch (error) {
        console.error("Error fetching inventory statistics:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div
      style={{
        height: "60vh",
        position: "relative",
        marginBottom: "1%",
        padding: "1%",
      }}
    >
      <Doughnut data={chartData} options={options} />
    </div>
  );
}
