// ###Build in imports
import React, { useState, useEffect } from "react";

// ###external imports
//react-router
import { Routes, Route, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// #ui
//mui
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//#antdesign
//icons
import {
  DashboardOutlined,
  DollarOutlined,
  ControlOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Popover } from "antd";

// ###file imports
// styles
import "./UserMng.css";
import "../../GlobalCss/Module/ModuleView.css";
import { useModuleContext } from "../../Contexts/ModuleContext";
////Access Controle Middle Wares
import {
  CheckUserModuleAccess,
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
} from "../../middleware/authorizationMiddleware";
//pages
import Dashboard from "./DashboardView/DashboardView";
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";
// subapps
import Department from "./Department/Department";

const UserMng = () => {
  //
  const navigate = useNavigate();
  //
  const { contextModuleId } = useModuleContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //navigate("configuration");
    setAnchorEl(event.currentTarget);
    //navigate("configuration");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={() => {
              navigate(".");
            }}
          >
            Users
          </h2>
        </div>

        <div className="moduleMngActionDiv">
          <div className="moduleMngActionBtns">
            <nav>
              <NavLink to="." end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <DashboardOutlined />
                    Dashboard
                  </Button>
                )}
              </NavLink>

              {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
                <NavLink to="list" end>
                  {({ isActive, isPending, isTransitioning }) => (
                    <Button className={isActive ? "active_module_nav" : ""}>
                      <UnorderedListOutlined />
                      List
                    </Button>
                  )}
                </NavLink>
              )}

              {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
                <NavLink to="department" end>
                  {({ isActive, isPending, isTransitioning }) => (
                    <Button className={isActive ? "active_module_nav" : ""}>
                      <UnorderedListOutlined />
                      Department
                    </Button>
                  )}
                </NavLink>
              )}

              {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
                <>
                  {CheckAdminAccess() ||
                  (CheckModuleCreateAccess(contextModuleId) &&
                    CheckModuleDeleteAccess(contextModuleId) &&
                    CheckModuleEditAccess(contextModuleId)) ? (
                    <Button onClick={handleClick}>
                      <ControlOutlined /> Configurations
                    </Button>
                  ) : null}

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    style={{
                      minWidth: "200px",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate(".");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <HomeOutlined
                          style={{ marginRight: "8px", fontSize: "18px" }}
                        />
                        1
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate(".");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        2
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate(".");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        2
                      </label>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </nav>
          </div>
        </div>
      </div>

      <div className="moduleDisplay">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="/list/*" element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
          {/* */}
          <Route path="/department/*" element={<Department />} />
        </Routes>
      </div>
    </div>
  );
};

export default UserMng;

{
  /* {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
            <button
              className="moduleCreateBtn"
              onClick={() => {
                navigate("create");
              }}
            >
              New User
            </button>
          ) : null} */
}
// </div>

{
  /* <Button
            onClick={() => {
              navigate(".");
            }}
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              border: "none",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
            onMouseEnter={(e) => {
              const target = e.target as HTMLButtonElement;
              target.style.backgroundColor = "#45a049";
              target.style.boxShadow = "0px 6px 10px rgba(0, 0, 0, 0.2)";
            }}
            onMouseLeave={(e) => {
              const target = e.target as HTMLButtonElement;
              target.style.backgroundColor = "#4CAF50";
              target.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
            }}
            onMouseDown={(e) => {
              const target = e.target as HTMLButtonElement;
              target.style.transform = "scale(0.95)";
            }}
            onMouseUp={(e) => {
              const target = e.target as HTMLButtonElement;
              target.style.transform = "scale(1)";
            }}
          >
            <DashboardOutlined
              style={{ fontSize: "20px", marginRight: "8px" }}
            />
            Dashboard
          </Button> */
}

{
  /* {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
            <Button
              onClick={() => {
                navigate("create", {
                  state: {
                    // moduleId: moduleid,
                  },
                });
              }}
              style={{
                backgroundColor: "#4CAF50",
                color: "white",
                padding: "10px 20px",
                fontSize: "16px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                borderRadius: "8px",
                border: "none",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.backgroundColor = "#45a049";
                target.style.boxShadow = "0px 6px 10px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.backgroundColor = "#4CAF50";
                target.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
              }}
              onMouseDown={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.transform = "scale(0.95)";
              }}
              onMouseUp={(e) => {
                const target = e.target as HTMLButtonElement;
                target.style.transform = "scale(1)";
              }}
            >
              <DollarOutlined
                style={{ fontSize: "20px", marginRight: "8px" }}
              />
              New Users
            </Button>
          )} */
}

{
  /* {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
            <div>
              {CheckAdminAccess() ||
              (CheckModuleCreateAccess(contextModuleId) &&
                CheckModuleDeleteAccess(contextModuleId) &&
                CheckModuleEditAccess(contextModuleId)) ? (
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#4CAF50",
                    color: "white",
                    padding: "10px 20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: "8px",
                    border: "none",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    const target = e.target as HTMLButtonElement;
                    target.style.backgroundColor = "#45a049";
                    target.style.boxShadow =
                      "0px 6px 10px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    const target = e.target as HTMLButtonElement;
                    target.style.backgroundColor = "#4CAF50";
                    target.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
                  }}
                  onMouseDown={(e) => {
                    const target = e.target as HTMLButtonElement;
                    target.style.transform = "scale(0.95)";
                  }}
                  onMouseUp={(e) => {
                    const target = e.target as HTMLButtonElement;
                    target.style.transform = "scale(1)";
                  }}
                >
                  <ControlOutlined /> Configurations
                </Button>
              ) : null}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                style={{
                  minWidth: "200px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <label
                    onClick={() => {
                      navigate("warehousemng");
                    }}
                    style={{
                      padding: "8px 16px",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <HomeOutlined
                      style={{ marginRight: "8px", fontSize: "18px" }}
                    />
                    one
                  </label>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <label
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{
                      padding: "8px 16px",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    two
                  </label>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <label
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{
                      padding: "8px 16px",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    three
                  </label>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <label
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{
                      padding: "8px 16px",
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    four
                  </label>
                </MenuItem>
              </Menu>
            </div>
          )} */
}
