//### Core
import React, { useEffect, useState } from "react";

//### Third Party
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Tooltip } from "antd";
import { Tabs as AntTabs } from "antd";
import { Select } from "antd";
import { Button, Form, Input, Space } from "antd";
import { Spin } from "antd";
import { Divider } from "antd";
import { Modal } from "antd";
import { Drawer } from "antd";
// ant design icons
import { CloudUploadOutlined } from "@ant-design/icons";

//### Absolute
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../../../Components/Notifications/Notifications";
//api methods
import { addOneWarehouseLocation } from "../../../../../API/Inventory/warehouse/warehouseLocationAPI";
import { getAllWarehouse } from "../../../../../API/Inventory/warehouse/warehouseAPI";
//interface
// interface default_type {
//   id: Number;
//   name: String;
// }
// //

const CreateView = () => {
  //fields
  const [name, setName] = useState("");
  const [warehouseID, setWarehouseID] = useState("");
  //default
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //data fields
  const [warehouseData, setWarehouseData] = useState([]);

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //### Methods
  //default methods
  const getWarehoseList = async () => {
    try {
      const res = await getAllWarehouse();
      setWarehouseData(res.data);
      console.log(res.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };
  //
  useEffect(() => {
    getWarehoseList();
  }, []);

  //###Constraints and onchanges

  //###CRUD Methods
  const handleAddModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      var req = await addOneWarehouseLocation({
        Name: name,
        Warehouse_ID: warehouseID,
        //
        Record_Note: recordNote,
      });
      notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={handleAddModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New Warehouse Location Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter warehouse location name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Name:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g Rack1-Shelf1"
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Select a parent warehouse"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Warehoues:
                </Tooltip>
                <Select
                  showSearch
                  placeholder="Select a Warehouse"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setWarehouseID(value)}
                  options={warehouseData.map((uom: any) => ({
                    value: uom.id,
                    label: uom.name,
                  }))}
                />
              </label>
            </div>
            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              onChange={setRecordNote}
            />
          </div>
        </Spin>
      </form>
    </div>
  );
};

export default CreateView;
