import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addCustomer = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_CUSTOMER_ADD_ONE}`,
      {
        name: props.Name,
        mobile: props.Mobile,
        email: props.Email,
        address: props.Address,
        recordNote: props.Record_Note,
        createdByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const get_One_Customer = async (ID: String) => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_CUSTOMER_GET_ONE}/${ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllCustomer = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_CUSTOMER_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateSingeleCustomer = async (props: any) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_CUSTOMER_UPDATE_ONE}/${props.ID}`,
      {
        name: props.Name,
        mobile: props.Mobile,
        email: props.Email,
        address: props.Address,
        updatedByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteSingeleCustomer = async (props: any) => {
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_CUSTOMER_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  addCustomer,
  get_One_Customer,
  getAllCustomer,
  updateSingeleCustomer,
  deleteSingeleCustomer,
};
