import React, { useEffect, useState } from "react";
import "./InventorySetting.css";
import { sessionStorage } from "es-storage";
//ant design
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import { Spin } from "antd";
import { Divider } from "antd";
//notifications
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";
//
import { updateTenantPreference } from "../../../API/Tenant/tenantAPI";
//

const InventorySetting = () => {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  const [isChecked, setIsChecked] = useState(false); // Initially false
  const [weeklyLowStockReport, setWeeklyLowStockReport] = useState(false);

  // On mount, retrieve the value from sessionStorage
  useEffect(() => {
    const storedValue = sessionStorage.get("product_edit_approval_required");
    const storedValue2 = sessionStorage.get("weekly_low_stock_report");
    if (storedValue !== null) {
      if (storedValue === "true") {
        setIsChecked(true); // Parse and set the boolean value
      } else {
        setIsChecked(false); // Parse and set the boolean value}
      }
      if (storedValue2 === "true") {
        setWeeklyLowStockReport(true); // Parse and set the boolean value
      } else {
        setWeeklyLowStockReport(false); // Parse and set the boolean value}
      }
    }
  }, []); // Run only once on mount

  // Handle checkbox change
  const onChange_Product_Edit_Approval_Required: CheckboxProps["onChange"] =
    async (e) => {
      try {
        //
        setLoading(true);
        //
        const checkedValue = e.target.checked;
        setIsChecked(checkedValue); // Update the UI state

        // Update sessionStorage
        sessionStorage.set(
          "product_edit_approval_required",
          JSON.stringify(checkedValue) // Store as a string
        );

        await updateTenantPreference();

        notifySuccess("Prefrencees Updated Successfully Refresh The Page");
      } catch (error) {
        console.error("Error updating preference:", error);
        notifyError(error);
      } finally {
        setLoading(false); // Set loading to false after request completion or error
      }
    };
  //
  const onChange_weeklyLowStockReport: CheckboxProps["onChange"] = async (
    e
  ) => {
    try {
      //
      setLoading(true);
      //
      const checkedValue = e.target.checked;
      setWeeklyLowStockReport(checkedValue); // Update the UI state

      // Update sessionStorage
      sessionStorage.set(
        "weekly_low_stock_report",
        JSON.stringify(checkedValue) // Store as a string
      );

      await updateTenantPreference();

      notifySuccess("Prefrencees Updated Successfully Refresh The Page...");
    } catch (error) {
      console.error("Error updating preference:", error);
      notifyError(error);
    } finally {
      setLoading(false); // Set loading to false after request completion or error
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div>
          <Checkbox
            onChange={onChange_Product_Edit_Approval_Required}
            checked={isChecked} // Reflect current state
          >
            Product Edit Approval Required
          </Checkbox>
          {isChecked ? (
            <label>
              Appoover{" "}
              <input type="text" defaultValue="Adminstrator" readOnly />
            </label>
          ) : null}
          <Divider orientation="left">""</Divider>
          <label>
            <p>
              Inventory Day: Comming Soon!
              <input type="date"></input>
            </p>
          </label>
        </div>
        <div>
          <Checkbox
            onChange={onChange_weeklyLowStockReport}
            checked={weeklyLowStockReport} // Reflect current state
          >
            Automated Weekly Low Stock report
          </Checkbox>
        </div>
      </Spin>
    </div>
  );
};

export default InventorySetting;
