//  ###Core
import React, { useEffect, useState } from "react";

// ### Third Party
//
import { useNavigate } from "react-router-dom";
//ant design
import { Empty, Spin } from "antd";
import { Modal as AntdModal } from "antd";
//material ui
import Box from "@mui/material/Box";
//mui icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
//mui grid table
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//

// ### Absolute
// notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../../../Components/Notifications/Notifications";
//api controllers
import {
  getAllUOMS,
  deleteSingeleUOM,
} from "../../../../../API/Inventory/uom/uom_api";

const ListView = () => {
  //### Varibles
  const navigate = useNavigate();
  //# State Fields
  //data states
  const [listViewDate, setListViewDate] = useState([]);
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //### Methods
  //default methodss
  const GetListViewData = async () => {
    try {
      var req: any = await getAllUOMS();
      console.log(req.data);
      setListViewDate(req.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };

  useEffect(() => {
    GetListViewData();
  }, []);
  //

  //###Constraints and onchanges

  //###CRUD Methods

  //###Action Methods
  const handleDeleteClick = async (id: any) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleUOM(id);
      console.log(call);
      GetListViewData();
      notifySuccess(call.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };

  const columns: GridColDef<(typeof listViewDate)[number]>[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      // editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      // editable: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 150,
      // editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(`update`, { state: { routeRecordId: id } })}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            //onClick={handleDeleteClick(id)}
            onClick={() => {
              AntdModal.confirm({
                title: "Confirm",
                content: "Are you sure you want to delete this record?",
                footer: (_, { OkBtn, CancelBtn }) => (
                  <>
                    {/* <Button>Custom Button</Button> */}
                    <CancelBtn />
                    <OkBtn />
                  </>
                ),
                onOk: () => {
                  handleDeleteClick(id);
                },
              });
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <div>
      <Spin spinning={loading}>
        {listViewDate && listViewDate.length > 0 ? (
          <DataGrid
            rows={listViewDate}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  // createdAt: false,
                  // createdBy: false,
                  // updatedAt: false,
                  // updatedBy: false,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default ListView;
