//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//

const notifyInfo = (msg: string) =>
  toast.info(msg || "Info!", {
    position: "top-right",
    theme: "colored",
  });
const notifySuccess = (msg: string) =>
  toast.success(msg || "Success!", {
    position: "top-right",
    theme: "colored",
  });

const notifyWarning = (msg: string) =>
  toast.warn(msg || "Warning!", {
    position: "top-right",
    theme: "colored",
  });

const notifyError = (msg: any) =>
  toast.error(msg || "Error!", {
    position: "top-right",
    theme: "colored",
  });

const handleCustomError = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    notifyError(error.response.data);
    notifyError(error.response.data.message);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    notifyError(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    notifyError(error.message || "An unknown error occurred.");
  }
};

export {
  notifyInfo,
  notifySuccess,
  notifyWarning,
  notifyError,
  handleCustomError,
};
