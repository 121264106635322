import * as React from "react";

// import "./Product.css";
//pages
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";

//
import { Routes, Route } from "react-router-dom";
////Access Controle Middle Wares
import {
  CheckModuleCreateAccess,
  CheckAdminAccess,
} from "../../../middleware/authorizationMiddleware";
//ant design
import { Button } from "antd";
//ant design icons
//react-icons
import { MdAddCircleOutline } from "react-icons/md";
//
import { useNavigate } from "react-router-dom";
//
import { sessionStorage } from "es-storage";
import "react-toastify/dist/ReactToastify.css";

//midewares

//material ui badge

// import Button from "@mui/material/Button";
//
import { useModuleContext } from "../../../Contexts/ModuleContext";
//

export default function ChecKIn_CheckOut() {
  //
  //
  // const location = useLocation();
  // const moduleid = location.state?.moduleId;
  const { contextModuleId, moduleLink } = useModuleContext();
  // const [moduleid, setModuleId] = useState(location.state?.moduleId);
  // console.log(moduleid);
  const navigate = useNavigate();
  //

  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h3
            className="moduleName"
            onClick={() => {
              navigate(".");
            }}
          >
            Check-in/Check-out
          </h3>
        </div>
        <div className="moduleMngActionDiv">
          {contextModuleId && CheckModuleCreateAccess(contextModuleId) ? (
            <Button
              type="primary"
              icon={<MdAddCircleOutline />}
              onClick={() => {
                navigate("create");
              }}
            >
              New
            </Button>
          ) : (
            "Access denied"
          )}
        </div>
      </div>
      <div className="moduleDisplay">
        <Routes>
          <Route index element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
        </Routes>
      </div>
    </div>
  );
}
