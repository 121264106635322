//### Core
import React, { useEffect, useState } from "react";

//### Third Parts
import { useNavigate } from "react-router-dom";
///ui
//antdesign
import { Button, Empty } from "antd";
import { Spin } from "antd";
import { Modal as AntdModal } from "antd";
//material ui
import Box from "@mui/material/Box";
//mui icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
//mui grid table
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//

//### Absolute
//style
import "./ListView.css"; //Local Css
//notifications
import {
  notifySuccess,
  notifyError,
  notifyInfo,
  notifyWarning,
  handleCustomError,
} from "../../../Components/Notifications/Notifications";
////Access Controle Middle Wares
import {
  CheckUserModuleAccess,
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
} from "../../../middleware/authorizationMiddleware";
//api
//
import {
  getAllCustomer,
  updateSingeleCustomer,
  deleteSingeleCustomer,
} from "../../../API/Customer/customerAPI";
//
//
import { useModuleContext } from "../../../Contexts/ModuleContext";

const ListView = () => {
  //### Varibles
  const navigate = useNavigate();

  //
  const { contextModuleId, moduleLink } = useModuleContext();
  // state fields
  //#data states
  const [ListViewDate, setListViewDate] = useState([]);

  //   ###Other fields
  //   spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //mui/list view columns
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "mobile", headerName: "Phone", width: 130 },
    { field: "email", headerName: "Email", width: 130 },
    { field: "address", headerName: "Address", width: 130 },
    { field: "createdBy", headerName: "Created By", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "updatedBy", headerName: "Updated By", width: 130 },
    { field: "updatedAt", headerName: "Updated At", width: 130 },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return contextModuleId
          ? [
              CheckModuleEditAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() =>
                    navigate(`update`, { state: { routeRecordId: id } })
                  }
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
              CheckModuleDeleteAccess(contextModuleId) ? (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  //onClick={handleDeleteClick(id)}
                  onClick={() => {
                    AntdModal.confirm({
                      title: "Confirm",
                      content: "Are you sure you want to delete this record?",
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          {/* <Button>Custom Button</Button> */}
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                      onOk: async () => {
                        await handleDeleteClick(id);
                      },
                    });
                  }}
                  color="inherit"
                />
              ) : (
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  className="textPrimary"
                  // onClick={handleEditClick(id)}
                  sx={{ color: "grey.500", opacity: 0.5 }} // Color for disabled Delete button
                />
              ),
            ]
          : [];
      },
    },
  ];

  //### Methods
  //default methods
  const GetListViewData = async () => {
    try {
      var req: any = await getAllCustomer();
      console.log(req.data);
      setListViewDate(req.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };

  useEffect(() => {
    GetListViewData();
  }, []);

  // Actions
  const handleDeleteClick = async (id: GridRowId) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleCustomer({ id: id });
      console.log(call);
      GetListViewData();
      notifySuccess("Success");
    } catch (err: any) {
      handleCustomError(err);
    }
  };

  return (
    <div className="ListView">
      <Spin spinning={loading}>
        <DataGrid
          rows={ListViewDate}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Spin>
    </div>
  );
};

export default ListView;
