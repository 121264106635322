//### Core
import React, { useEffect, useState } from "react";

// ###external imports
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// #antdesign
import { Spin } from "antd";
import { Tooltip } from "antd";
import { Checkbox } from "antd";
import { Button, Form, Input, Space } from "antd";
// ant design icons
import { CloudUploadOutlined } from "@ant-design/icons";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
//antd tabs
import { Select } from "antd";
import { Tabs as AntTabs } from "antd";
import type { TabsProps as AntTabsProps } from "antd";

// #material Ui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

//### Absolute
// styles
import "./CreateView.css";
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../../Components/Notifications/Notifications";

//Api controlers
import {
  addProduct,
  getAllProduct,
  updateSingeleProduct,
  deleteSingeleProduct,
  SaveUpdateSingeleProductRequest,
} from "../../../../API/Inventory/product/productAPI";
import { getAllProductCategories } from "../../../../API/Inventory/product/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../../API/Inventory/product/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../../API/Inventory/supplier/supplierAPI";
import { getAllWarehouse } from "../../../../API/Inventory/warehouse/warehouseAPI";
import { getAllWarehouseLocation } from "../../../../API/Inventory/warehouse/warehouseLocationAPI";
import { getAllUOMS } from "../../../../API/Inventory/uom/uom_api";

// Define interfaces
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
//

// #product inter
interface Product {
  id: number;
  Name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

interface UOM {
  id: number;
  name: string;
  // createdAt: string;
  // updatedAt: string;
}

const CreateView = () => {
  // ### Variables

  // ###product states
  const [stockType, setStockType] = useState("");
  const [canBeSold, setCanBeSold] = useState(false);
  const [canBePurchased, setCanBePurchased] = useState(false);
  // General States
  const [productInternalRef, setProductInternalRef] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [productMinStockQuantity, setProductMinStockQuantity] = useState("");
  const [productMaxStockQuantity, setProductMaxStockQuantity] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productCostPrice, setProductCostPrice] = useState("");
  const [productPurchasedMethod, setProductPurchasedMethod] = useState("");
  const [productModel, setProductModel] = useState("");
  const [productBrand, setProductBrand] = useState("");
  //
  const [attributes, setAttributes] = useState<
    Array<{ name: string; value: string }>
  >([]);
  //assets
  const [productImage, setProductImage] = useState("");
  const [productDoc, setProductDoc] = useState("");
  //relation states
  const [productUOMID, setProductUOMID] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productCategorieID, setProductCategorieID] = useState("");
  const [productCategoryUUID, setProductCategoryUUID] = useState("");
  const [productSubCategorieID, setProductSubCategorieID] = useState("");
  const [productSubCategoryUUID, setProductSubCategoryUUID] = useState("");
  const [productWarehouseID, setProductWarehouseID] = useState("");
  const [productWarehouseLocationID, setProductWarehouseLocationID] =
    useState("");
  const [productSupplierID, setProductSupplierID] = useState("");
  //record note
  const [recordNote, setRecordNote] = useState("");
  //data states
  const [productUOMA, setProductUOMA] = useState<UOM[]>([]);
  const [productWarehouseA, setProductWarehouseA] = useState([]);
  const [productSupplierA, setProductSupplierA] = useState([]);
  const [productCategorieA, setProductCategorieA] = useState([]);
  const [productWarehouseData, setproductWarehouseData] = useState<any[]>([]);
  const [warehouseLocationData, setWarehouseLocationData] = useState<any[]>([]);
  const [productSubCategorieData, setProductSubCategorieData] = useState([]);
  const [filterdSubCategorieData, setfilterdSubCategorieData] = useState<any[]>(
    []
  );
  const [filterdWarehouseLocationData, setFilterdWarehouseLocationData] =
    useState<any[]>([]);
  const [productData, setproductData] = useState<Product[]>([]);
  //data vaiabes
  const ProductCategorie: any = [];
  const ProductWarehouse: any = [];
  const ProductSupplier: any = [];
  const EditSubCategoryArray: any = [];

  // ###other states
  //loading state
  const [loading, setLoading] = React.useState<boolean>(false);

  const [form] = Form.useForm();

  //### Methods
  //@@default methods

  const HandleGetProductCategories = async () => {
    //e.preventDefault();
    try {
      const call = await getAllProductCategories();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      //console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductCategorie.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      console.log(ProductCategorie);
      setProductCategorieA(ProductCategorie);
    } catch (err) {
      console.log(err);
    }
  };
  //
  const HandleGetProductSubCategories = async () => {
    //e.preventDefault();
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;
      for (let i = 0; i < data.length; i++) {
        EditSubCategoryArray.push({
          id: data[i].id,
          name: data[i].name,
          uuid: data[i].uuid,
        });
      }
      //console.log(EditSubCategoryArray);
      setProductSubCategorieData(call.data);
    } catch (err) {
      console.log(err);
    }
  };
  //
  const HandleUOM = async () => {
    //e.preventDefault();
    try {
      const call = await getAllUOMS();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data);
      // for (let i = 0; i < data.length; i++) {
      //   ProductWarehouse.push({
      //     id: data[i].id,
      //     name: data[i].name,
      //   });
      // }
      // console.log(ProductWarehouse);
      setProductUOMA(
        data.map((uom: any) => ({
          id: uom.id,
          name: uom.name,
        }))
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  //
  const HandleGetWarehouses = async () => {
    //e.preventDefault();
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductWarehouse.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductWarehouse);
      setProductWarehouseA(ProductWarehouse);
    } catch (err) {
      console.log(err);
    }
  };
  const HandleGetWarehouseLocations = async () => {
    //e.preventDefault();
    try {
      const call = await getAllWarehouseLocation();
      //console.log(call.data[0].id);
      // const data: any[] = call.data;
      // console.log(data.length);
      // for (let i = 0; i < data.length; i++) {
      //   ProductWarehouse.push({
      //     id: data[i].id,
      //     name: data[i].name,
      //   });
      // }
      // console.log(ProductWarehouse);
      setWarehouseLocationData(call.data);
    } catch (err) {
      console.log(err);
    }
  };
  //
  const HandleGetSuppliers = async () => {
    // e.preventDefault();
    try {
      const call = await getAllSupplier();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        ProductSupplier.push({
          id: data[i].id,
          name: data[i].name,
        });
      }
      console.log(ProductSupplier);
      setProductSupplierA(ProductSupplier);
    } catch (err) {
      console.log(err);
    }
  };
  //
  const featchPreData = async () => {
    await HandleGetSuppliers();
    await HandleGetWarehouses();
    await HandleGetWarehouseLocations();
    await HandleGetProductCategories();
    await HandleGetProductSubCategories();
    await HandleUOM();
  };
  useEffect(() => {
    ///GetProducts();
    featchPreData();
  }, []);
  //

  //### CRUD Methods
  const handleAddProduct = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await addProduct({
        Stock_Type: stockType,
        Can_Be_Sold: canBeSold,
        Can_Be_Purchased: canBePurchased,
        InternalRef: productInternalRef,
        Name: productName,
        Model: productModel,
        Brand_Name: productBrand,
        Purchase_Method: productPurchasedMethod,
        Description: productDescription,
        Image: productImage,
        Document: productDoc,
        Quantity: productQuantity,
        UOM_ID: productUOMID,
        MinStockQuantity: productMinStockQuantity,
        MaxStockQuantity: productMaxStockQuantity,
        Cost_Price: productCostPrice,
        Sales_Price: productPrice,
        Supplier_ID: productSupplierID,
        Warehouse_ID: productWarehouseID,
        Warehouse_Location_ID: productWarehouseLocationID,
        ProductCategorie_ID: productCategorieID,
        ProductSubCategorie_ID: productSubCategorieID,
        ProductAttributes: attributes,
        Record_Note: recordNote,
      });
      console.log(req);
      //GetProducts();
      notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  //##Event Handlers
  const onFinish = (values: any) => {
    // const event = new Event("form");
    // event.preventDefault();
    // alert(values);
    console.log("Received values of form:", values);
    setAttributes(values.attributes || []);
  };
  //
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };
  //
  const handleDocChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const fileData = reader.result as string;
        // console.log("Uploaded File Data: ", fileData);
        setProductDoc(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
  };
  //
  // Function to update internal reference based on Model and Brand Name
  const updateInternalReference = () => {
    const internalRef = `${productCategoryUUID}_${productSubCategoryUUID}_${productBrand}_${productModel}`;
    setProductInternalRef(internalRef);
  };
  // Update internal reference whenever Model or Brand Name changes
  useEffect(() => {
    updateInternalReference();
  }, [
    productModel,
    productBrand,
    productCategorieID,
    productCategoryUUID,
    productSubCategoryUUID,
  ]);
  //
  const handleFilterdSubCategoryChange = async (
    parentCategoryID: number | String
  ) => {
    console.log(parentCategoryID);
    console.log(productSubCategorieData);
    //
    let fillterdSubCategory = productSubCategorieData.filter(
      (value: any) => value.ProductCategorieId == parentCategoryID
    );
    console.log(fillterdSubCategory);
    // let array = [];
    // array.push(fillterdSubCategory);
    setfilterdSubCategorieData(fillterdSubCategory);
  };
  const handleFilterdWarehouseLocationChange = async (
    warehouseID: number | String
  ) => {
    //
    let fillterdSubCategory = warehouseLocationData.filter(
      (value: any) => value.WarehouseId == warehouseID
    );
    console.log(fillterdSubCategory);
    // let array = [];
    // array.push(fillterdSubCategory);
    setFilterdWarehouseLocationData(fillterdSubCategory || []);
  };
  //

  //Model Methods
  // Handle inventory Categorization selection
  const handleStockTypeChange = (event: any) => {
    const selectedType = event.target.value;
    setStockType(selectedType);
    // Auto-set checkboxes based on stock type
    // if (selectedType === "goods") {
    //   setCanBeSold(true);
    //   setCanBePurchased(true);
    // } else if (selectedType === "services") {
    //   setCanBeSold(true);
    //   setCanBePurchased(false);
    // } else if (selectedType === "consumables") {
    //   setCanBeSold(false);
    //   setCanBePurchased(true);
    // }
  };
  //

  //# UI Rendering Logic methods

  const [tabValue, settabValue] = React.useState(0);

  //
  return (
    <div className="ModuleCreateView">
      <form className="ModuleCreateViewForm" onSubmit={handleAddProduct}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New Product Form</h4>
              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>

            <div className="form-row">
              <label className="productlabels">
                <Tooltip
                  title="Good: inished products for sale,
                  Material: raw materials used in production,
                  Service: non-physical items,
                  Consumable: used in operations,
                  Asset: fixed assets."
                  color="#333333"
                  placement="bottomLeft"
                >
                  <select
                    value={stockType}
                    onChange={handleStockTypeChange}
                    required
                  >
                    <option value="">Select a Stock Type</option>
                    <option value="good">Goods</option>
                    <option value="material">Materials</option>
                    <option value="service">Services</option>
                    <option value="consumable">Consumables</option>
                    <option value="asset">Asset</option>
                  </select>
                </Tooltip>
              </label>
            </div>

            <div className="form-row">
              <label>
                <Checkbox
                  checked={canBeSold}
                  onChange={() => setCanBeSold(!canBeSold)}
                  // disabled={stockType === "consumables"} // Disable if not applicable
                >
                  Can be Sold
                </Checkbox>
              </label>

              <label>
                <Checkbox
                  checked={canBePurchased}
                  onChange={() => setCanBePurchased(!canBePurchased)}
                  // disabled={stockType === "services"} // Disable if not applicable
                >
                  Can be Purchased
                </Checkbox>
              </label>
            </div>

            <div className="form-row">
              <label className="productlabels">
                <Tooltip
                  title="Unique ID generated from category, brand & model. !"
                  color="#333333"
                  placement="bottomLeft"
                >
                  <span>Internal Reference:</span>
                </Tooltip>
                <input
                  className="InternalRefInput"
                  type="text"
                  placeholder="01_01_Ustunel_S4CR8D03/15"
                  value={productInternalRef}
                  minLength={2}
                  maxLength={100}
                  required
                  readOnly
                />
              </label>
              <label>
                Product Name:
                <input
                  type="text"
                  placeholder="e.g Pump"
                  onChange={(event) => setProductName(event.target.value)}
                  required
                />
              </label>
            </div>

            <div className="form-row">
              <label className="productlabels">
                Product Quantity:
                <input
                  type="number"
                  placeholder="Enter quantity"
                  onChange={(event) => setProductQuantity(event.target.value)}
                  min={1}
                  required
                />
              </label>

              <label>
                UOM:
                <Select
                  showSearch
                  placeholder="Select a UOM"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setProductUOMID(value)}
                  options={productUOMA.map((uom: any) => ({
                    value: uom.id,
                    label: uom.name,
                  }))}
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                Brand Name:
                <input
                  type="text"
                  placeholder="e.g Caterpillar, Ustunel"
                  onChange={(event) => setProductBrand(event.target.value)}
                  required
                />
              </label>
              <label className="productlabels">
                Model:
                <input
                  type="text"
                  placeholder="S4CR8D03/15"
                  onChange={(event) => setProductModel(event.target.value)}
                  minLength={2}
                  maxLength={100}
                  required
                />
              </label>
              <label className="productlabels">
                <Tooltip
                  title="Price at which the product is sold to customers!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Sales Price:
                </Tooltip>
                <input
                  type="number"
                  placeholder="100.00"
                  onChange={(event) => setProductPrice(event.target.value)}
                  defaultValue={0}
                  min={0}
                  required
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                Cost Price:
                <input
                  type="number"
                  placeholder="100.00"
                  onChange={(event) => setProductCostPrice(event.target.value)}
                  defaultValue={0}
                  min={0}
                  required
                />
              </label>
              <label>
                Product Category:
                <select
                  multiple={false}
                  onChange={async (event) => {
                    const selectedOption = event.target.value;
                    const selectedCategoryId = selectedOption.split(",")[0];
                    const selectedCategoryUuid = selectedOption.split(",")[1];

                    setProductCategorieID(selectedCategoryId);
                    setProductCategoryUUID(selectedCategoryUuid);
                    handleFilterdSubCategoryChange(selectedCategoryId);
                  }}
                  required
                >
                  <option value="">Select a Product Categorie</option>
                  {productCategorieA.map((categorie: any) => (
                    <option
                      key={categorie.id}
                      value={`${categorie.id},${categorie.uuid}`}
                    >
                      {categorie.name}
                    </option>
                  ))}
                </select>
              </label>

              <label className="productlabels">
                <Tooltip
                  title="Product sub-categories found. Filtred based on main Categories.Set up categories in configuration settings!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Sub Category:
                </Tooltip>
                <select
                  multiple={false}
                  onChange={(event) => {
                    const selectedOption = event.target.value;
                    const selectedCategoryId = selectedOption.split(",")[0];
                    const selectedCategoryUuid = selectedOption.split(",")[1];

                    setProductSubCategorieID(selectedCategoryId);
                    setProductSubCategoryUUID(selectedCategoryUuid);
                  }}
                  required
                >
                  <option value="">Select Sub Categorie</option>
                  {filterdSubCategorieData.map((categorie: any) => (
                    <option
                      key={categorie.id}
                      value={`${categorie.id},${categorie.uuid}`}
                    >
                      {categorie.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div className="form-row">
              {(stockType === "good" || stockType === "material") && (
                <label className="productlabels">
                  Product Supplier:
                  <select
                    multiple={false}
                    onChange={(event) =>
                      setProductSupplierID(event.target.value)
                    }
                    required
                  >
                    <option value="">Select a supplier</option>
                    {productSupplierA.map((supplier: any) => (
                      <option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </option>
                    ))}
                  </select>
                </label>
              )}
              {(stockType === "good" || stockType === "material") && (
                <div>
                  <label className="productlabels">
                    Warehouse:
                    <select
                      multiple={false}
                      onChange={(event) => {
                        setProductWarehouseID(event.target.value);
                        handleFilterdWarehouseLocationChange(
                          event.target.value
                        );
                      }}
                      required
                    >
                      <option value="">Select a Warehouse</option>
                      {productWarehouseA.map((Warehouse: any) => (
                        <option key={Warehouse.id} value={Warehouse.id}>
                          {Warehouse.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="productlabels">
                    Warehouse Internal Location:
                    <select
                      multiple={false}
                      onChange={(event) =>
                        setProductWarehouseLocationID(event.target.value)
                      }
                      required
                    >
                      <option value="">Select</option>
                      {filterdWarehouseLocationData.map((Warehouse: any) => (
                        <option key={Warehouse.id} value={Warehouse.id}>
                          {Warehouse.name}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              )}

              <label className="productlabels">
                Method of Purchase:
                <select
                  multiple={false}
                  onChange={(event) =>
                    setProductPurchasedMethod(event.target.value)
                  }
                  required
                >
                  <option value="">Select a Method of Purchase</option>
                  <option value="direct purchase">Direct Purchase</option>
                  <option value="import">Import</option>
                </select>
              </label>
            </div>

            <div className="form-row">
              <label className="productlabels">
                Product Image:
                <div className="image-upload-container">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    multiple={false}
                    name="Add Image"
                  />
                  {productImage && (
                    <img
                      src={productImage}
                      alt="Uploaded"
                      className="ProductPreviewImg"
                    />
                  )}
                </div>
              </label>
              <label className="productlabels">
                Product Document:
                <div className="image-upload-container">
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleDocChange}
                    multiple={false}
                    name="Add Document"
                  />
                </div>
              </label>
              <label className="productlabels">
                Product Description:
                <textarea
                  placeholder="Detailed Product Description e.g., Ustunel 4” Submersible Pump"
                  onChange={(event) =>
                    setProductDescription(event.target.value)
                  }
                ></textarea>
              </label>
            </div>
            {stockType === "good" && (
              <div className="form-row">
                <AntTabs
                  defaultActiveKey="1"
                  items={[
                    {
                      key: "1",
                      label: "Stock levels",
                      children: (
                        <>
                          <label className="productlabels">
                            Minimum Stock Quantity:
                            <input
                              type="number"
                              onChange={(event) =>
                                setProductMinStockQuantity(event.target.value)
                              }
                              min={0}
                            />
                          </label>
                          <label className="productlabels">
                            Maximum Stock Quantity:
                            <input
                              type="number"
                              // value={NewProductMaxStockQuantity}
                              onChange={(e) =>
                                setProductMaxStockQuantity(e.target.value)
                              }
                              min={0}
                            />
                          </label>
                        </>
                      ),
                    },
                    {
                      key: "2",
                      label: "Attributes",
                      children: (
                        <>
                          <Form
                            name="dynamic_form_nest_item"
                            onFinish={onFinish}
                            // onValuesChange={onFinish}
                            style={{ maxWidth: 600 }}
                            autoComplete="off"
                            // onValuesChange={(changedValues, allValues) => {
                            //   console.log("Changed values:", allValues);

                            //   form.setFieldsValue({
                            //     attributes: allValues.attributes,
                            //   });
                            // }}
                            onValuesChange={(changedValues, allValues) => {
                              console.log("Changed values:", changedValues);
                              console.log("All form values:", allValues);
                              console.log(
                                "Current attributes state:",
                                attributes
                              );

                              if (allValues.attributes) {
                                setAttributes(allValues.attributes);
                                console.log(
                                  "Updated attributes:",
                                  allValues.attributes
                                );
                              }
                            }}
                            initialValues={{
                              attributes: attributes,
                            }}
                          >
                            <Form.List name="attributes">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={key}
                                      style={{
                                        display: "flex",
                                        marginBottom: 8,
                                      }}
                                      align="baseline"
                                    >
                                      <Form.Item
                                        {...restField}
                                        name={[name, "name"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing attribute name",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Attribute Name" />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "value"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing attribute value",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Attribute Value" />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add Attribute
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                            {/* <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={(e) => e.preventDefault()}
                      >
                        Save Attributes
                      </Button>
                    </Form.Item> */}
                          </Form>
                        </>
                      ),
                    },
                  ]}
                ></AntTabs>
              </div>
            )}
          </div>
          <ReactQuill theme="snow" onChange={setRecordNote} />
        </Spin>
      </form>
    </div>
  );
};

export default CreateView;
