// core
import React, { useState, useEffect } from "react";

//### Third Party
import { sessionStorage } from "es-storage";
//react-route
import { Routes, Route, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//import { Outlet, Link } from "react-router-dom";
//ant design
import {
  DashboardOutlined,
  DollarOutlined,
  UnorderedListOutlined,
  ControlOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
//mui
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Transfer from "./Transfer/Transfer";
import { useModuleContext } from "../../Contexts/ModuleContext";
//react-icons
import { FaWarehouse } from "react-icons/fa";
import { BsBookshelf } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { TbRulerMeasure } from "react-icons/tb";

//### Absolute
import "./Inventory.css";
import "../../GlobalCss/Module/ModuleView.css";
////Access Controle Middle Wares
import {
  CheckUserModuleAccess,
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
} from "../../middleware/authorizationMiddleware";
//pages
import DashboardView from "./DashboardView/DashboardView";
import Product from "./Product/Product";
import WarehouseMng from "./WarehouseMng/WarehouseMng";
import Location from "./WarehouseMng/Location/Location";
import ProductCategorie from "./ProductCategory/ProductCategorie";
import ProductSubCategorie from "./ProductSubCategory/ProductSubCategorie";
import UOM from "./UOM/UOM/UOM";
import UOM_Category from "./UOM/UOM_Category/UOM_Category";
import ChecKIn_CheckOut from "./CheckIn_CheckOut/CheckIn_CheckOut";
//

const Inventory = () => {
  const { contextModuleId, moduleLink } = useModuleContext();

  const navigate = useNavigate();
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);
  //

  useEffect(() => {
    if (userRole === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  //mui  menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //navigate("configuration");
    setAnchorEl(event.currentTarget);
    //navigate("configuration");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={() => {
              navigate("./");
            }}
          >
            Inventory
          </h2>
        </div>

        <div className="moduleMngActionDiv">
          <div className="moduleMngActionBtns">
            <nav>
              <NavLink to="." end>
                {({ isActive, isPending, isTransitioning }) => (
                  <Button className={isActive ? "active_module_nav" : ""}>
                    <DashboardOutlined />
                    Dashboard
                  </Button>
                )}
              </NavLink>

              {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
                <NavLink to="product">
                  {({ isActive, isPending, isTransitioning }) => (
                    <Button className={isActive ? "active_module_nav" : ""}>
                      <UnorderedListOutlined />
                      Products
                    </Button>
                  )}
                </NavLink>
              )}

              {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
                <NavLink to="checkincheckout">
                  {({ isActive, isPending, isTransitioning }) => (
                    <Button className={isActive ? "active_module_nav" : ""}>
                      <UnorderedListOutlined />
                      Check-in/Check-out
                    </Button>
                  )}
                </NavLink>
              )}

              {contextModuleId && CheckUserModuleAccess(contextModuleId) && (
                <>
                  {CheckAdminAccess() ||
                  (CheckModuleCreateAccess(contextModuleId) &&
                    CheckModuleDeleteAccess(contextModuleId) &&
                    CheckModuleEditAccess(contextModuleId)) ? (
                    <Button onClick={handleClick}>
                      <ControlOutlined /> Configurations
                    </Button>
                  ) : null}

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    style={{
                      minWidth: "200px",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("warehousemng");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FaWarehouse />
                        Warehouse Management
                      </label>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("location");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BsBookshelf />
                        Warehouse Locations
                      </label>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("productcategory");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BiCategory />
                        Product Categories
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("productsubcategory");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <BiCategory />
                        Sub Categories
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("producttransfer");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Transfer
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("uom_category");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TbRulerMeasure />
                        UOM Category
                      </label>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <label
                        onClick={() => {
                          navigate("uom");
                        }}
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TbRulerMeasure />
                        UOM
                      </label>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </nav>
          </div>
        </div>
      </div>

      <div className="moduleDisplay">
        <Routes>
          <Route index element={<DashboardView />} />
          <Route path="/product/*" element={<Product />} />
          <Route path="/checkincheckout/*" element={<ChecKIn_CheckOut />} />
          <Route path="/warehousemng/*" element={<WarehouseMng />} />
          <Route path="/location/*" element={<Location />} />
          <Route path="/productcategory/*" element={<ProductCategorie />} />
          <Route
            path="/productsubcategory/*"
            element={<ProductSubCategorie />}
          />
          <Route path="/producttransfer" element={<Transfer />} />
          <Route path="/uom_category/*" element={<UOM_Category />} />
          <Route path="/uom/*" element={<UOM />} />
        </Routes>
      </div>
    </div>
  );
};

export default Inventory;
