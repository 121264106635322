//### Core
import React, { useEffect, useState } from "react";

//### Third Party
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Tooltip } from "antd";
import { Spin } from "antd";
import { Button } from "antd";
// ant design icons
import { CloudUploadOutlined } from "@ant-design/icons";
//### Absolute
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../../Components/Notifications/Notifications";
//api methods
import { addWarehouse } from "../../../../API/Inventory/warehouse/warehouseAPI";

//###interfaces
// interface default_type {
//   id: Number;
//   name: String;
// }
//

const CreateView = () => {
  //fields
  const [warehouseID, setWarehouseID] = useState("");
  const [warehouseName, setWarehouseName] = useState("");
  const [warehouseAddress, setWarehouseAddress] = useState("");
  //default
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  //

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);

  //### Methods
  //default methods

  //### CRUD Methods
  const handleAddModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      var req = await addWarehouse({
        Name: warehouseName,
        Address: warehouseAddress,
        //
        Record_Note: recordNote,
      });
      notifySuccess(req.data);
      // Reset Form
      setWarehouseName("");
      setWarehouseAddress("");
      setRecordNote("");
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={handleAddModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New Warehouse Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter warehouse name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Name:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g Central Warehouse"
                  onChange={(event) => setWarehouseName(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter the warehouse address"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Address:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g 123 Main Street, City"
                  onChange={(event) => setWarehouseAddress(event.target.value)}
                  required
                />
              </label>
            </div>
            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              onChange={setRecordNote}
            />
          </div>
        </Spin>
      </form>
    </div>
  );
};

export default CreateView;
