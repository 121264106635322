import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "antd";
import {
  BoxPlotOutlined,
  TagsOutlined,
  AppstoreOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import "./DashboardView.css";
//Notifications
import { notifyError } from "../../../Components/Notifications/Notifications";
//charts
import { Stock_Type_Chart1 } from "./Charts/Stock_Type/Stock_Type";
import { Warehouse_Product_Chart1 } from "./Charts/Warehouse_Product/Warehouse_Product";
//Api
import {
  addProduct,
  getAllProduct,
  updateSingeleProduct,
  deleteSingeleProduct,
  SaveUpdateSingeleProductRequest,
} from "../../../API/Inventory/product/productAPI";
import { getAllProductCategories } from "../../../API/Inventory/product/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../API/Inventory/product/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../API/Inventory/supplier/supplierAPI";
import { getAllWarehouse } from "../../../API/Inventory/warehouse/warehouseAPI";

const InventoryDashboard = () => {
  //
  const [TotProduct, setTotProduct] = useState(0);
  const [TotProductCategorie, setTotProductCategorie] = useState(0);
  const [TotProductSubCategorie, setTotProductSubCategorie] = useState(0);
  const [TotWarehouse, setTotWarehouse] = useState(0);
  //
  const [LastProduct, setLastProduct] = useState<any>("");

  const metrics = [
    {
      title: "Total Products",
      value: TotProduct,
      icon: <BoxPlotOutlined />,
      className: "total-products",
    },
    {
      title: "Categories",
      value: TotProductCategorie,
      icon: <TagsOutlined />,
      className: "categories",
    },
    {
      title: "Sub-Categories",
      value: TotProductSubCategorie,
      icon: <AppstoreOutlined />,
      className: "sub-categories",
    },
    {
      title: "Warehouses",
      value: TotWarehouse,
      icon: <HomeOutlined />,
      className: "warehouses",
    },
  ];

  const recentActivities = [
    {
      name: LastProduct.name,
      createdAt: LastProduct.createdAt,
      updatedAt: LastProduct.updatedAt,
    },
  ];

  const GetProducts = async () => {
    try {
      var req: any = await getAllProduct();
      const data: any[] = req.data;
      // setproductData(req.data);
      console.log(req.data);
      setTotProduct(data.length);
      //
      const lastNumber = data[data.length - 1];
      // Check if the data array has any products
      if (data.length > 0) {
        // Get the last product
        const lastProduct = data[data.length - 1];
        console.log("Last product:", lastProduct);

        // Set the last product
        setLastProduct(lastProduct);
      } else {
        console.log("No products found.");
        // setLastProduct([]); // Or handle as appropriate
      }
    } catch (error: any) {
      if (error.response) {
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        notifyError(error.request);
      } else {
        notifyError(error.message);
      }
    }
  };

  //HandleGetProductCategories
  const HandleGetProductCategories = async () => {
    try {
      const call = await getAllProductCategories();

      const data: any[] = call.data;

      setTotProductCategorie(data.length);
    } catch (error: any) {
      if (error.response) {
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        notifyError(error.request);
      } else {
        notifyError(error.message);
      }
    }
  };

  const HandleGetProductSubCategories = async () => {
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;

      setTotProductSubCategorie(data.length);
    } catch (error: any) {
      if (error.response) {
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        notifyError(error.request);
      } else {
        notifyError(error.message);
      }
    }
  };

  const HandleGetWarehouses = async () => {
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;

      setTotWarehouse(data.length);
    } catch (error: any) {
      if (error.response) {
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        notifyError(error.request);
      } else {
        notifyError(error.message);
      }
    }
  };
  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchCategories = async () => {
      await GetProducts();
      await HandleGetProductCategories();
      await HandleGetProductSubCategories();
      await HandleGetWarehouses();
    };

    fetchCategories();
  }, []);

  return (
    <div className="dashboard">
      <h1 className="dashboard-title">Inventory Dashboard</h1>
      <Stock_Type_Chart1 />
      <Row gutter={16} className="metrics-row">
        {metrics.map((metric) => (
          <Col span={12} key={metric.title}>
            <Card className="metric-card">
              <div className="metric-icon">{metric.icon}</div>
              <h3>{metric.title}</h3>
              <p className="metric-value">{metric.value}</p>
            </Card>
          </Col>
        ))}
      </Row>
      <h2>Recent Activities</h2>
      <Table
        dataSource={recentActivities}
        columns={[
          { title: "Name", dataIndex: "name", key: "name" },
          { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
          { title: "Updated At", dataIndex: "updatedAt", key: "updatedAt" },
        ]}
        pagination={false}
        rowKey="name"
      />
    </div>
  );
};
export default InventoryDashboard;
