import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addProductSubCategories = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_ADD_ONE}`,
      {
        parentID: props.ParentCategorieID,
        name: props.Name,
        uuid: props.UUID,
        description: props.Description,
        //
        recordNote: props.Record_Note,
        createdByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getOneProductSubCategorie = async (ID: String) => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_GET_ONE}/${ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    console.log(err);
    throw err;
  }
};

const getAllProductSubCategories = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_GET_ALL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteSingeleProductSubCategory = async (props: any) => {
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateSingeleProductSubCategory = async (props: any) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_PRODUCT_SUB_CATEGORY_UPDATE_ONE}/${props.ID}`,
      {
        parentID: props.ParentCategorieID,
        name: props.Name,
        uuid: props.UUID,
        description: props.Description,
        //
        recordNote: props.Record_Note,
        updatedByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  addProductSubCategories,
  getOneProductSubCategorie,
  getAllProductSubCategories,
  deleteSingeleProductSubCategory,
  updateSingeleProductSubCategory,
};
