//### Core
import React, { useEffect, useState } from "react";

//### Third Party
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Tooltip } from "antd";
import { Tabs as AntTabs } from "antd";
import { Select } from "antd";
import { Button, Form, Input, Space } from "antd";
import { Spin } from "antd";
import { Divider } from "antd";
import { Modal } from "antd";
import { Drawer } from "antd";
// ant design icons
import { CloudUploadOutlined } from "@ant-design/icons";
//### Absolute
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../../../Components/Notifications/Notifications";

//api methods
import {
  addUOM_Category,
  getAllUOM_Categories,
} from "../../../../../API/Inventory/uom/uom_category_api";

//interface
// interface default_type {
//   id: Number;
//   name: String;
// }
//

const CreateView = () => {
  //variables

  //state fields
  const [name, seName] = useState("");
  //
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //### Methods
  //default methods

  //### CRUD Methods
  const handleAddModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await addUOM_Category({
        Name: name,
        Record_Note: recordNote,
      });
      notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={handleAddModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New UOM Category Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>
            <div className="form-row">
              <label>
                Name:
                <input
                  type="text"
                  placeholder="e.g Unit"
                  onChange={(event) => seName(event.target.value)}
                  required
                />
              </label>
            </div>
            <ReactQuill theme="snow" onChange={setRecordNote} />
          </div>
        </Spin>
      </form>

      {/* modals */}
    </div>
  );
};

export default CreateView;
