import React, { useEffect, useState } from "react";
import "./UpdateView.css";
import "../../../../GlobalCss/Module/UpdateView/UpdateView.css";
//
import { sessionStorage } from "es-storage";
//Api
import {
  getSingleProduct,
  updateSingeleProduct,
  SaveUpdateSingeleProductRequest,
  ReplenishProduct,
} from "../../../../API/Inventory/product/productAPI";
import { addSupplier } from "../../../../API/Inventory/supplier/supplierAPI";
import { getAllUOMS } from "../../../../API/Inventory/uom/uom_api";
//
import { getAllProductCategories } from "../../../../API/Inventory/product/productcategoriesAPI";
import { getAllProductSubCategories } from "../../../../API/Inventory/product/productsubcategoriesAPI ";
import { getAllSupplier } from "../../../../API/Inventory/supplier/supplierAPI";
import { getAllWarehouse } from "../../../../API/Inventory/warehouse/warehouseAPI";
// notification
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "../../../../Components/Notifications/Notifications";
//
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// ant design
import { Badge, Checkbox, Input, Select, Tooltip } from "antd";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";
import { Table } from "antd";
import { Button, Form, Space } from "antd";
import { Avatar } from "antd";
//ant design icons
import { CloudSyncOutlined } from "@ant-design/icons";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
//antd tabs
import { Tabs as AntTabs } from "antd";
import type { TabsProps as AntTabsProps } from "antd";
////material Ui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// interfaces
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface UOM {
  id: number;
  name: string;
  // createdAt: string;
  // updatedAt: string;
}

interface Product {
  id: number;
  Name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

const UpdateView = () => {
  // const [attributes, setAttributes] = useState<
  //   Array<{ id: Number; name: string; value: string }>
  // >([]);
  const [attributes, setAttributes] = useState([{ name: "", value: "" }]);

  const onFinish = (values: any) => {
    console.log("Received values of form:", values);
    // setAttributes(values.attributes || []);
  };
  const handleValuesChange = (changedValues: any, allValues: any) => {
    // Use this to handle form changes
    console.log("Changed Values:", changedValues);
    console.log("All Values:", allValues);

    if (changedValues.attributes) {
      // Update the attributes state if needed (though Form handles it now)
      setAttributes(allValues.attributes);
    }
  };
  //Relelish modal
  const [isReplenishModalOpen, setIsReplenishModalOpen] = useState(false);
  const showReplenishModa = () => {
    setIsReplenishModalOpen(true);
  };

  const handleOkReplenishModa = async () => {
    try {
      await ReplenishProduct({
        ID: routeRecordID,
        WarehouseID: EditReplenishWarehouseID as unknown as Number,
        ReplenishQuantity: EditReplenishQnt as unknown as Number,
      });
      await load_mutable_data(routeRecordID);
      setIsReplenishModalOpen(false);
      notifySuccess("");
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    }
  };

  const handleCancelReplenishModa = () => {
    setIsReplenishModalOpen(false);
  };
  //
  const location = useLocation();
  //Routed RecordID
  const [routeRecordID, setRouteRecordID] = useState(
    location.state?.routeRecordId
  );

  useEffect(() => {
    if (!routeRecordID && location.state?.routeRecordId) {
      const newRouteUserID = location.state.routeUserId;
      setRouteRecordID(newRouteUserID);
      // setEditUserID(newRouteUserID);
    }
  }, [location.state, routeRecordID]);

  useEffect(() => {
    const fetchData = async () => {
      if (routeRecordID) {
        await load_mutable_data(routeRecordID);
        console.log("Attributes : ", attributes);
        setDataLoading(false);
      }
    };
    fetchData();
  }, []);

  //API Data States
  const [productData, setproductData] = useState<Product[]>([]);
  const [APIMainProductCategory, setAPIMainProductCategory] = useState<any[]>(
    []
  );
  let ProductCategorieArray: any = [];
  const [APIProductSubCategory, setAPIProductSubCategory] = useState<any[]>([]);
  let ProductSubCategorieArray: any = [];
  const [APIProductSupplier, setAPIProductSupplier] = useState<any[]>([]);
  const [APIProductWarehouse, setAPIProductWarehouse] = useState<any[]>([]);
  //Edit variabls
  const [stockType, setStockType] = useState("");
  const [canBeSold, setCanBeSold] = useState(false);
  const [canBePurchased, setCanBePurchased] = useState(false);
  //
  const [ProductID, setProductID] = useState(0);
  const [EditProductName, setEditProductName] = useState("");
  const [EditProductInternalRef, setEditProductInternalRef] = useState("");
  const [EditProductCategorieID, setEditProductCategorieID] = useState("");
  const [EditProductSubCategorieID, setEditProductSubCategorieID] =
    useState("");
  const [EditProductSubCategorieUUID, setEditProductSubCategorieUUID] =
    useState("");
  const [EditProductWarehouseID, setEditProductWarehouseID] = useState("");
  const [EditProductModel, setEditProductModel] = useState("");
  const [EditProductBrand, setEditProductBrand] = useState("");
  const [EditProductCategoryUUID, setEditProductCategoryUUID] = useState("");
  const [EditedProductDoc, setEditedProductDoc] = useState("");
  const [EditedProductPurchasedMethod, setEditedProductPurchasedMethod] =
    useState("");
  const [EditedProductDescription, setEditedProductDescription] = useState("");
  const [EditProductQuantity, setEditProductQuantity] = useState("");
  const [productUOMID, setProductUOMID] = useState("");
  const [EditProductMinStockQuantity, setEditProductMinStockQuantity] =
    useState(0);
  const [EditProductMaxStockQuantity, setEditProductMaxStockQuantity] =
    useState(0);
  const [EditProductCostPrice, setEditProductCostPrice] = useState("");
  const [EditProductSalePrice, setEditProductSalePrice] = useState("");
  const [EditProductSupplierID, setEditProductSupplierID] = useState("");
  const [EditProductImage, setEditProductImage] = useState("");
  const [EditRecordNote, setEditRecordNote] = useState("");
  const [EditReplenishQnt, setEditReplenishQnt] = useState("");
  const [EditReplenishWarehouseID, setEditReplenishWarehouseID] = useState("");
  const [NewProductDoc, setNewProductDoc] = useState("");
  //
  const [Relation_Product_Warehouse, setRelation_Product_Warehouse] = useState(
    []
  );
  //
  const [ProductCreatedBy, setProductCreatedBy] = useState("");
  const [ProductCreatedAt, setProductCreatedAt] = useState("");
  const [ProductUpdatedBy, setProductUpdatedBy] = useState("");
  const [ProductUpdatedAt, setProductUpdatedAt] = useState("");
  //
  //spin state
  const [dataLoading, setDataLoading] = React.useState<boolean>(true);
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  //model quick creates
  const [isQuickSupplierModalOpen, setisQuickSupplierModalOpen] =
    useState(false);
  const [supplierDetails, setSupplierDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    address: "",
  });
  //data states
  const [productUOMA, setProductUOMA] = useState<UOM[]>([]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSupplierDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const showQuickSupplierModal = (e: any) => {
    e.preventDefault();
    setisQuickSupplierModalOpen(true);
  };

  const handleOkQuickSupplierModal = async (e: any) => {
    e.preventDefault();
    try {
      await addSupplier({
        Name: supplierDetails.name,
        Mobile: supplierDetails.mobile,
        Email: supplierDetails.email,
        Address: supplierDetails.address,
      });
      await HandleGetSuppliers();
      notifySuccess("Supplier Quick Create");
      setisQuickSupplierModalOpen(false);
    } catch (error) {
      notifyError("Error:Supplier Quick Creat");
    }
  };

  const handleCancelQuickSupplierModal = () => {
    setisQuickSupplierModalOpen(false);
  };

  // Module Methods
  const load_mutable_data = async (id: Number) => {
    try {
      await HandleGetProductCategories();
      await HandleGetSuppliers();
      await HandleGetWarehouses();
      await HandleUOM();

      await HandleGetProductSubCategories();
      var req: any = await getSingleProduct(id);
      const data = req.data || []; // Default to an empty array in case `call.data` is undefined
      console.log(data);
      setproductData(data);
      console.log(productData);

      // Wait for APIMainProductCategory to be populated before proceeding

      // Access the current state of categories and subcategories
      // Using the functional update to ensure you're getting the latest state
      const currentMainProductCategories: any = await new Promise((resolve) => {
        setTimeout(() => resolve(ProductCategorieArray), 0);
      });

      const currentSubProductCategories: any = await new Promise((resolve) => {
        setTimeout(() => resolve(ProductSubCategorieArray), 0);
      });

      // Find the edited product category and subcategory
      const editedProductCategorie: any = currentMainProductCategories.find(
        (row: any) => row.id === data.ProductCategorieId
      );

      console.log("ProductCategorieId:", data.ProductCategorieId);

      const editedProductSubCategorie: any = currentSubProductCategories.find(
        (row: any) => row.id === data.ProductSubCategorieId
      );

      //
      // Check if editedProductCategorie is defined before accessing uuid
      if (editedProductCategorie) {
        setEditProductCategoryUUID(editedProductCategorie.uuid);
      } else {
        console.log(
          "No matching main category found for ID:",
          data.ProductCategorieId
        );
        //setEditProductCategoryUUID(null); // Handle undefined case
      }

      // Check if editedProductSubCategorie is defined before accessing uuid
      if (editedProductSubCategorie) {
        setEditProductSubCategorieUUID(editedProductSubCategorie.uuid);
      } else {
        console.log(
          "No matching subcategory found for ID:",
          data.ProductSubCategorieId
        );
        //setEditProductSubCategorieUUID(null); // Handle undefined case
      }
      //

      setProductID(data.id);
      setStockType(data.stock_type);
      setCanBeSold(data.can_be_sold);
      setCanBePurchased(data.can_be_purchased);
      setEditProductName(data.name);
      setEditProductInternalRef(data.internalref);
      setEditProductModel(data.model);
      setEditProductBrand(data.brand_name);
      setEditProductQuantity(data.totalQuantity);
      setProductUOMID(data.uomID);
      setEditProductMinStockQuantity(data.minStockQuantity);
      setEditProductMaxStockQuantity(data.maxStockQuantity);
      setEditedProductPurchasedMethod(data.purchase_method);
      setEditProductCostPrice(data.cost_price);
      setEditProductSalePrice(data.sales_price);

      // setProductImage(editedRow.image);
      setEditProductImage(data.image);
      setEditedProductDoc(data.document);
      setEditProductCategorieID(data.ProductCategorieId);
      setEditProductSubCategorieID(data.ProductSubCategorieId);
      //
      //
      setEditProductCategoryUUID(editedProductCategorie.uuid);
      setEditProductSubCategorieUUID(editedProductSubCategorie.uuid);
      //
      setEditProductSupplierID(data.SupplierId);
      setEditProductWarehouseID(data.WarehouseId);
      setRelation_Product_Warehouse(data.Product_Warehouses);
      // setAttributes(data.ProductAttribute);
      setAttributes(
        (data.ProductAttributes || []).map(
          (att: {
            Attribute: { id: number; name: String; value: String };
          }) => ({
            id: att.Attribute?.id,
            name: att.Attribute?.name || "",
            value: att.Attribute?.value || "",
          })
        )
      );
      console.log(data.Product_Warehouses);
      console.log("Attributes : ", data.ProductAttributes);
      setEditedProductDescription(data.description);
      setEditRecordNote(data.recordNote);
      //auther
      if (data.Creater) {
        setProductCreatedBy(data.Creater.fullName);
      }
      setProductCreatedAt(data.createdAt);
      if (data.Updater) {
        setProductUpdatedBy(data.Updater.fullName);
      }
      setProductUpdatedAt(data.updatedAt);
      //
      if (data.document) {
        //setProductDocBadge(1);
      } else {
        //setProductDocBadge(0);
      }
      if (data.quantity) {
        //setProductInventoryBadge(editedRow.quantity);
      } else {
        //setProductInventoryBadge(0);
      }
      //
      //console.log("ProductWarehouse:" + data.Product_Warehouses);
      //handleOpen();
      //
      //setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    } finally {
      // setDataLoading(true);
    }
    // alert(`CID=${EditProductCategorieID} \n CUUID=${EditProductCategoryUUID}`);
  };

  const HandleEdit = async (event: any) => {
    event.preventDefault();
    try {
      //
      setUpdateLoading(true);
      //
      //
      // alert(
      //   `ID==${supplierID} \n Name==${supplierName} \n Email==${supplierEmail} \n Address==${supplierAddress}`
      // );
      //
      // const isApprovalRequired = JSON.parse(
      //   sessionStorage.get("product_edit_approval_required") || "false"
      // );

      // if (typeof isApprovalRequired !== "boolean") {
      //   const err = new Error("isApprovalRequired is not a boolean");
      //   throw err;
      // }
      //
      //
      //
      // if (!isApprovalRequired) {
      const call = await updateSingeleProduct({
        ID: ProductID,
        Stock_Type: stockType,
        Can_Be_Sold: canBeSold,
        Can_Be_Purchased: canBePurchased,
        InternalRef: EditProductInternalRef,
        Name: EditProductName,
        Model: EditProductModel,
        Brand_Name: EditProductBrand,
        Document: NewProductDoc,
        Description: EditedProductDescription,
        Image: EditProductImage,
        Quantity: EditProductQuantity,
        UOM_ID: productUOMID,
        MinStockQuantity: EditProductMinStockQuantity,
        MaxStockQuantity: EditProductMaxStockQuantity,
        Cost_Price: EditProductCostPrice,
        Sales_Price: EditProductSalePrice,
        ProductCategorie_ID: EditProductCategorieID,
        ProductSubCategorie_ID: EditProductSubCategorieID,
        Supplier_ID: EditProductSupplierID,
        Purchase_Method: EditedProductPurchasedMethod,
        Warehouse_ID: EditProductWarehouseID,
        ProductAttributes: attributes,
        recordNote: EditRecordNote,
      });
      console.log(call);
      //GetProducts();
      notifySuccess("");
      // }
      // if (isApprovalRequired) {
      //   const call2 = await SaveUpdateSingeleProductRequest({
      //     ID: ProductID,
      //     InternalRef: EditProductInternalRef,
      //     Name: EditProductName,
      //     Model: EditProductModel,
      //     Brand_Name: EditProductBrand,
      //     Document: NewProductDoc,
      //     Description: EditedProductDescription,
      //     Image: EditProductImage,
      //     Quantity: EditProductQuantity,
      //     MinStockQuantity: EditProductMinStockQuantity,
      //     MaxStockQuantity: EditProductMaxStockQuantity,
      //     Cost_Price: EditProductCostPrice,
      //     Sales_Price: EditProductSalePrice,
      //     ProductCategorie_ID: EditProductCategorieID,
      //     ProductSubCategorie_ID: EditProductSubCategorieID,
      //     Supplier_ID: EditProductSupplierID,
      //     Purchase_Method: EditedProductPurchasedMethod,
      //     Warehouse_ID: EditProductWarehouseID,
      //     recordNote: EditRecordNote,
      //   });
      //   console.log(call2);
      //   //GetProducts();
      //   notifySuccess(call2.data.message || "");
      //   // Notify user of email status
      //   const emailStatusMessage = call2.data.emailSent
      //     ? "Email reminder sent successfully."
      //     : "Failed to send email reminder.";
      //   notifyInfo(`Email Reminder Status: ${emailStatusMessage}`);
      // }
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    } finally {
      //
      setUpdateLoading(false);
      //
    }
  };

  const HandleUOM = async () => {
    //e.preventDefault();
    try {
      const call = await getAllUOMS();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data);
      // for (let i = 0; i < data.length; i++) {
      //   ProductWarehouse.push({
      //     id: data[i].id,
      //     name: data[i].name,
      //   });
      // }
      // console.log(ProductWarehouse);
      setProductUOMA(
        data.map((uom: any) => ({
          id: uom.id,
          name: uom.name,
        }))
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  //

  const HandleGetSuppliers = async () => {
    //e.preventDefault();
    try {
      const call = await getAllSupplier();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      const formattedSupplier: any = data.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));

      console.log(formattedSupplier);
      setAPIProductSupplier(formattedSupplier);
      //console.log(ProductSupplierA);
      //setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
      throw error;
    }
  };

  const HandleGetProductCategories = async () => {
    //e.preventDefault();
    try {
      const call = await getAllProductCategories();
      const data = call.data || []; // Default to an empty array in case `call.data` is undefined

      // Map each product category into the desired format
      const formattedCategories: any = data.map((item: any) => ({
        id: item.id,
        name: item.name,
        uuid: item.uuid,
      }));

      console.log(formattedCategories);
      ProductCategorieArray = formattedCategories;
      setAPIMainProductCategory(formattedCategories);
      //   setAPIMainProductCategory((prevCategories) => {
      //     const newCategories = [...prevCategories, ...formattedCategories];
      //     console.log("Updated APIMainProductCategory:", newCategories); // Check the updated state
      //     return newCategories;
      //   });
      //
      // This correctly merges the existing and new categories
      //   setAPIMainProductCategory((prevCategories) => [
      //     ...prevCategories,
      //     ...formattedCategories,
      //   ]);
      //setAPIMainProductCategory((a) => [...a, formattedCategories]);
      //console.log(ProductCategorieA);
      console.log(APIMainProductCategory);
      //   setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
      throw error;
    }
  };

  const HandleGetProductSubCategories = async () => {
    //e.preventDefault();
    try {
      const call: any = await getAllProductSubCategories();
      const data: any[] = call.data;
      const formattedArray: any = data.map((item: any) => ({
        id: item.id,
        name: item.name,
        uuid: item.uuid,
      }));
      //   for (let i = 0; i < data.length; i++) {
      //     EditSubCategoryArray.push({
      //       id: data[i].id,
      //       name: data[i].name,
      //       uuid: data[i].uuid,
      //     });
      //   }
      //console.log(EditSubCategoryArray);
      //setProductSubCategorieData(call.data);
      ProductSubCategorieArray = formattedArray;
      setAPIProductSubCategory(formattedArray);
      //   setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
      throw error;
    }
  };

  const HandleGetWarehouses = async () => {
    //e.preventDefault();
    try {
      const call = await getAllWarehouse();
      //console.log(call.data[0].id);
      const data: any[] = call.data;
      console.log(data.length);
      const formattedArray: any = data.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));

      console.log(formattedArray);
      setAPIProductWarehouse(formattedArray);
      //setDataLoading(false);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
      throw error;
    }
  };

  //
  const handleStockTypeChange = (event: any) => {
    const selectedType = event.target.value;
    setStockType(selectedType);

    // Auto-set checkboxes based on stock type
    // if (selectedType === "goods") {
    //   setCanBeSold(true);
    //   setCanBePurchased(true);
    // } else if (selectedType === "services") {
    //   setCanBeSold(true);
    //   setCanBePurchased(false);
    // } else if (selectedType === "consumables") {
    //   setCanBeSold(false);
    //   setCanBePurchased(true);
    // }
  };
  const handleUpdateImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Set the base64 string as the source of the image
        setEditProductImage(reader.result as string);
      };
      // Read the file as a data URL
      reader.readAsDataURL(file);
    }
  };

  const handleDocChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const fileData = reader.result as string;
        // console.log("Uploaded File Data: ", fileData);
        setEditedProductDoc(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
  };

  // Function to update internal reference based on Model and Brand Name
  const EditInternalReference = () => {
    const internalRef = `${EditProductCategoryUUID}_${EditProductSubCategorieUUID}_${EditProductBrand}_${EditProductModel}`;
    setEditProductInternalRef(internalRef);
  };

  const handleEditCategoryChange = (event: any) => {
    const selectedOption = event.target.value;
    const [selectedCategoryId, selectedCategoryUuid] =
      selectedOption.split(",");

    setEditProductCategorieID(selectedCategoryId);
    setEditProductCategoryUUID(selectedCategoryUuid);
  };

  const handleEditSubCategoryChange = (event: any) => {
    const selectedOption = event.target.value;
    const [selectedCategoryId, selectedCategoryUuid] =
      selectedOption.split(",");

    setEditProductSubCategorieID(selectedCategoryId);
    setEditProductSubCategorieUUID(selectedCategoryUuid);
  };

  useEffect(() => {
    EditInternalReference();
  }, [
    productData,
    EditProductCategoryUUID,
    EditProductSubCategorieUUID,
    EditProductBrand,
    EditProductModel,
  ]);

  //mui tabs
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [tabValue, settabValue] = React.useState(0);
  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    settabValue(newValue);
  };
  //

  return (
    <div className="ModuleUpdateView">
      <Spin spinning={dataLoading}>
        <Spin spinning={updateLoading}>
          <form className="ModuleUpdateViewForm" onSubmit={HandleEdit}>
            <div className="ModuleUpdateViewForm_Header">
              <div className="FormTitle">
                <h4>Update Product Form</h4>
              </div>
              <div className="bages">
                <Space size="middle">
                  <Badge count={EditProductQuantity} title="On Hand">
                    <Avatar shape="square" size="large" />
                  </Badge>
                </Space>
                {EditedProductDoc && (
                  <Avatar
                    size={64}
                    icon={<FilePdfOutlined />}
                    onClick={() => window.open(EditedProductDoc, "_blank")}
                  />
                  // <a href={EditedProductDoc} download="document.pdf">
                  //   <Avatar size={64} icon={<FilePdfOutlined />} />
                  // </a>
                  // <a href={EditedProductDoc} download="document.pdf">
                  //   <Avatar size={64} icon={<FilePdfOutlined />} />
                  // </a>
                )}
              </div>

              {/* {JSON.parse(
                sessionStorage.get("product_edit_approval_required")
              ) ? (
                <div>
                  <button
                    className="moduleSaveBtn"
                    type="button"
                    onClick={showReplenishModa}
                  >
                    Replenish
                  </button>
                  <button className="moduleSaveBtn" type="submit">
                    Submit Edit
                  </button>
                  submit edit is temporarly off flag
                </div>
              ) : (
                <div>
                  <button
                    className="moduleSaveBtn"
                    type="button"
                    onClick={showReplenishModa}
                  >
                    Replenish
                  </button>
                  <button className="moduleSaveBtn" type="submit">
                    Update
                  </button>
                </div>
              )} */}
              <div>
                <button
                  className="moduleSaveBtn"
                  type="button"
                  onClick={showReplenishModa}
                >
                  Replenish
                </button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<CloudSyncOutlined />}
                >
                  Update
                </Button>
              </div>
            </div>
            <div className="form-row">
              <label className="productlabels">
                <Tooltip
                  title="Select the type of stock: Goods (physical items), Services (non-physical), or Consumables (used in operations)."
                  color="#333333"
                  placement="bottomLeft"
                >
                  <select value={stockType} onChange={handleStockTypeChange}>
                    <option value="">Select a Stock Type</option>
                    <option value="good">Goods</option>
                    <option value="material">Materials</option>
                    <option value="service">Services</option>
                    <option value="consumable">Consumables</option>
                  </select>
                </Tooltip>
              </label>
            </div>
            <div className="form-row">
              <label>
                <Checkbox
                  checked={canBeSold}
                  onChange={() => setCanBeSold(!canBeSold)}
                  // disabled={stockType === "consumables"} // Disable if not applicable
                >
                  Can be Sold
                </Checkbox>
              </label>

              <label>
                <Checkbox
                  checked={canBePurchased}
                  onChange={() => setCanBePurchased(!canBePurchased)}
                  // disabled={stockType === "services"} // Disable if not applicable
                >
                  Can be Purchased
                </Checkbox>
              </label>
            </div>
            <div className="form-row">
              <label className="productInternalReflabel">
                Internal Reference:
                <input
                  type="text"
                  placeholder=""
                  value={EditProductInternalRef}
                  minLength={2}
                  maxLength={100}
                  required
                  readOnly
                ></input>
              </label>
              <label className="productlabels">
                Product ID :
                <input
                  type="text"
                  placeholder=""
                  value={ProductID}
                  required
                  readOnly
                ></input>
              </label>
              <label className="productlabels">
                Product Name :
                <input
                  type="text"
                  placeholder=""
                  value={EditProductName}
                  onChange={(event) => setEditProductName(event.target.value)}
                  minLength={2}
                  maxLength={100}
                  required
                ></input>
              </label>
            </div>
            <div className="form-row">
              <label className="productlabels">
                Brand Name:
                <input
                  type="text"
                  value={EditProductBrand}
                  placeholder="e.g Caterpillar, Ustunel"
                  onChange={(event) => setEditProductBrand(event.target.value)}
                  minLength={2}
                  maxLength={100}
                  required
                ></input>
              </label>

              <label className="productlabels">
                Model :
                <input
                  type="text"
                  placeholder=""
                  value={EditProductModel}
                  onChange={(event) => setEditProductModel(event.target.value)}
                  min={1}
                  required
                ></input>
              </label>
              <label className="productlabels">
                Sale Price :
                <input
                  type="text"
                  placeholder=""
                  value={EditProductSalePrice}
                  onChange={(event) =>
                    setEditProductSalePrice(event.target.value)
                  }
                  min={1}
                  required
                ></input>
              </label>
            </div>
            <div className="form-row">
              <label className="productlabels">
                Cost Price :
                <input
                  type="text"
                  placeholder=""
                  value={EditProductCostPrice}
                  onChange={(event) =>
                    setEditProductCostPrice(event.target.value)
                  }
                  min={1}
                  required
                ></input>
              </label>
              <label className="productlabels">
                Product Category:
                <select
                  multiple={false}
                  value={`${EditProductCategorieID},${EditProductCategoryUUID}`}
                  onChange={(e) => handleEditCategoryChange(e)}
                  required
                >
                  <option value="1">Select a Product Category</option>
                  {APIMainProductCategory &&
                    APIMainProductCategory.map((categorie: any) => (
                      <option
                        key={categorie.id}
                        value={`${categorie.id},${categorie.uuid}`}
                      >
                        {categorie.name}
                      </option>
                    ))}
                </select>
              </label>

              <label className="productlabels">
                Sub Category:
                <select
                  multiple={false}
                  value={`${EditProductSubCategorieID},${EditProductSubCategorieUUID}`}
                  onChange={(e) => handleEditSubCategoryChange(e)}
                  required
                >
                  <option value="1">Select Sub Category</option>
                  {APIProductSubCategory &&
                    APIProductSubCategory.map((categorie: any) => (
                      <option
                        key={categorie.id}
                        value={`${categorie.id},${categorie.uuid}`}
                      >
                        {categorie.name}
                      </option>
                    ))}
                </select>
              </label>
            </div>
            <div className="form-row">
              {(stockType === "good" || stockType === "material") && (
                <label className="productlabels">
                  Product Supplier :{" "}
                  <PlusCircleOutlined
                    onClick={(e) => showQuickSupplierModal(e)}
                  />
                  <select
                    multiple={false}
                    value={EditProductSupplierID}
                    onChange={(event) =>
                      setEditProductSupplierID(event.target.value)
                    }
                    required
                  >
                    <option value="">Select a supplier</option>
                    {APIProductSupplier &&
                      APIProductSupplier.map((supplier: any) => (
                        <option key={supplier.id} value={supplier.id}>
                          {supplier.name}
                        </option>
                      ))}
                  </select>
                </label>
              )}
              <label className="productlabels">
                Method of Purchase:
                <select
                  multiple={false}
                  value={EditedProductPurchasedMethod}
                  onChange={(event) =>
                    setEditedProductPurchasedMethod(event.target.value)
                  }
                  required
                >
                  <option value="">Select a Method of Purchase</option>
                  <option value="direct purchase">Direct Purchase</option>
                  <option value="import">Import</option>
                </select>
              </label>
              <label className="productlabels">
                Product Image :
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleUpdateImageChange}
                  />
                  {EditProductImage && (
                    <img
                      src={EditProductImage}
                      alt="Uploaded"
                      className="ProductPreviewImg"
                      style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                      }}
                    />
                  )}
                </div>
              </label>
              <label className="productlabels">
                Product Document:
                <div className="image-upload-container">
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleDocChange}
                    multiple={false}
                    name="Add Document"
                  />
                </div>
              </label>
            </div>

            <div className="form-row">
              {(stockType === "good" || stockType === "material") && (
                <label className="productlabels">
                  Stock:
                  {Relation_Product_Warehouse &&
                  Relation_Product_Warehouse.length > 0 ? (
                    <Table
                      dataSource={Relation_Product_Warehouse.map((pw: any) => ({
                        key: pw.id, // Unique key for each row
                        warehouse: pw.Warehouse.name, // Data for Warehouse column
                        quantity: pw.quantity, // Data for Quantity column
                      }))}
                      columns={[
                        {
                          title: "Warehouse",
                          dataIndex: "warehouse",
                          key: "warehouse",
                        },
                        {
                          title: "Quantity",
                          dataIndex: "quantity",
                          key: "quantity",
                        },
                      ]}
                    />
                  ) : (
                    <p>No warehouses available</p>
                  )}
                </label>
              )}
            </div>

            <div className="form-row">
              <label className="productInternalReflabel">
                Total Quantity:
                <input
                  type="text"
                  placeholder=""
                  value={EditProductQuantity}
                  required
                  readOnly
                ></input>
              </label>

              <label className="productInternalReflabel">
                UOM:
                <Select
                  showSearch
                  placeholder="Select a UOM"
                  value={productUOMID}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setProductUOMID(value)}
                  options={productUOMA.map((uom: any) => ({
                    value: uom.id,
                    label: uom.name,
                  }))}
                />
              </label>
            </div>
            <div className="form-row">
              {(stockType === "good" || stockType === "material") && (
                <AntTabs
                  defaultActiveKey="1"
                  items={[
                    {
                      key: "1",
                      label: "Stock levels",
                      children: (
                        <>
                          <label className="productInternalReflabel">
                            Minimum Stock Quantity:
                            <input
                              type="number"
                              value={EditProductMinStockQuantity}
                              onChange={(event) =>
                                setEditProductMinStockQuantity(
                                  Number(event.target.value)
                                )
                              }
                              // min={1}
                            ></input>
                          </label>
                          <label className="productInternalReflabel">
                            Maximum Stock Quantity:
                            <input
                              type="number"
                              value={EditProductMaxStockQuantity}
                              onChange={(event) =>
                                setEditProductMaxStockQuantity(
                                  Number(event.target.value)
                                )
                              }
                              // min={1}
                            ></input>
                          </label>
                        </>
                      ),
                    },
                    {
                      key: "2",
                      label: "Attributes",
                      children: (
                        <>
                          <Form
                            name="dynamic_form_nest_item"
                            onFinish={onFinish}
                            style={{ maxWidth: 600 }}
                            // autoComplete="off"
                            // onValuesChange={(changedValues, allValues) => {
                            //   console.log("Changed values:", changedValues);
                            //   console.log("All form values:", allValues);
                            //   console.log("Current attributes state:", attributes);

                            //   if (allValues.attributes) {
                            //     setAttributes(allValues.attributes);
                            //     console.log(
                            //       "Updated attributes:",
                            //       allValues.attributes
                            //     );
                            //   }
                            // }}
                            onValuesChange={handleValuesChange}
                            // onValuesChange={(changedValues, allValues) => {
                            //   // Directly update the attributes state on form value change
                            //   if (changedValues.attributes) {
                            //     setAttributes(allValues.attributes);
                            //   }
                            // }}
                            initialValues={{
                              attributes: attributes,
                            }}
                          >
                            <Form.List name="attributes">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={key}
                                      style={{
                                        display: "flex",
                                        marginBottom: 8,
                                      }}
                                      align="baseline"
                                    >
                                      <Form.Item
                                        {...restField}
                                        name={[name, "name"]}
                                        hasFeedback={true}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing attribute name",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Attribute Name"
                                          required
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "value"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing attribute value",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Attribute Value"
                                          required
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add Attribute
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Form>
                        </>
                      ),
                    },
                  ]}
                />
              )}
            </div>

            <div className="form-row">
              <label className="productlabels">
                Product Description:
                <textarea
                  placeholder="Detail Product Description"
                  value={EditedProductDescription}
                  onChange={(event) =>
                    setEditedProductDescription(event.target.value)
                  }
                ></textarea>
              </label>
            </div>
            <div className="ModuleCreateViewForm_Bottom">
              <ReactQuill
                theme="snow"
                value={EditRecordNote}
                onChange={setEditRecordNote}
              />
            </div>
            <div className="recordAuthor">
              <label className="productlabels">
                Created By:
                <input
                  type="text"
                  placeholder=""
                  value={ProductCreatedBy}
                  readOnly
                ></input>
              </label>

              <label className="productlabels">
                Created At:
                <input
                  type="text"
                  placeholder=""
                  value={ProductCreatedAt}
                  readOnly
                ></input>
              </label>

              <label className="productlabels">
                Last Updated By:
                <input
                  type="text"
                  placeholder=""
                  value={ProductUpdatedBy}
                  readOnly
                ></input>
              </label>

              <label className="productlabels">
                Last Updated At :
                <input
                  type="text"
                  placeholder=""
                  value={ProductUpdatedAt}
                  readOnly
                ></input>
              </label>
            </div>
          </form>
        </Spin>
      </Spin>
      {/* Modals */}
      <>
        <Modal
          title="Replenish Product"
          open={isReplenishModalOpen}
          onOk={handleOkReplenishModa}
          onCancel={handleCancelReplenishModa}
        >
          <p>Quanity</p>
          <Input
            type="number"
            placeholder="Quanity"
            onChange={(e) => setEditReplenishQnt(e.target.value)}
            required
          ></Input>
          <p>Warehouse</p>
          <select
            id="warehouse-select"
            multiple={false}
            // value={EditProductSupplierID}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setEditReplenishWarehouseID(selectedValue); // update the state with the selected warehouse ID
            }}
            required
          >
            <option value="">Select a Warehouse</option>
            {Relation_Product_Warehouse &&
            Relation_Product_Warehouse.length > 0 ? (
              Relation_Product_Warehouse.map((pw: any) => (
                <option key={pw.id} value={pw.Warehouse.id}>
                  {pw.Warehouse.name}
                  {" - Current In Stock: " + pw.quantity}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No warehouses available
              </option>
            )}
          </select>
        </Modal>
        {/* Product Quick Create Modal */}
        <Modal
          title="Supplier Quick Create Modal"
          open={isQuickSupplierModalOpen}
          onOk={handleOkQuickSupplierModal}
          onCancel={handleCancelQuickSupplierModal}
        >
          <Input
            name="name"
            type="text"
            placeholder="Name"
            value={supplierDetails.name}
            onChange={handleInputChange}
            required
          />
          <Input
            name="mobile"
            type="text"
            placeholder="Mobile"
            value={supplierDetails.mobile}
            onChange={handleInputChange}
          />
          <Input
            name="email"
            type="email"
            placeholder="Email"
            value={supplierDetails.email}
            onChange={handleInputChange}
          />
          <Input
            name="address"
            type="text"
            placeholder="Address"
            value={supplierDetails.address}
            onChange={handleInputChange}
          />
        </Modal>
      </>
    </div>
  );
};

export default UpdateView;
