import React, { useEffect, useState } from "react";
import "./Landing.css";
//context
import { useTenantSubscriptionContext } from "../../Contexts/TenantSubscriptionContext";
//
import { ModuleProvider, useModuleContext } from "../../Contexts/ModuleContext";
//
import { sessionStorage } from "es-storage";
//
import { CheckUserModuleAccess } from "../../middleware/authorizationMiddleware";
//
import { Routes, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ResponsiveAppBar from "./responsiveAppBar";
//pages
import UserMng from "../../modules/UserMng/UserMng";
import Inventory from "../../modules/Inventory/Inventory";
import Profile from "../Profile/Profile";
import Settings from "../Settings/Settings";
import CustomerMng from "../../modules/CustomerMng/CustomerMng";
import SupplierMng from "../../modules/SupplierMng/SupplierMng";
import Documentations from "../Documentations/Documentations";
import ProfileEdit from "../Profile/ProfileEdit/ProfileEdit";
import Discuss from "../../modules/Discuss/Discuss";
import Project from "../../modules/Project/Project";
import Accounting from "../../modules/Accounting/Accounting";
import Sales from "../../modules/Sales/Sales";
import Purchase from "../../modules/Purchase/Purchase";
import LogHistory from "../../modules/LogHistory/LogHistory";
import Dashboard from "../../modules/Dashboard/Dashboard";
import MyTasks from "../../modules/MyTasks/MyTasks";
import MailSync from "../../modules/MailSync/MailSync";
//demo modules
import Demo1 from "../../modules/SampleApp1/Demo";

//asset icons

//antd
import { Spin } from "antd";

// interface Module {
//   id: number;
//   name: string;
//   description: string;
//   iconUrl: string;
//   link: string;
// }

const Landing = () => {
  const { modules, addModule } = useTenantSubscriptionContext();
  // Spin state
  const [loading, setLoading] = useState<boolean>(false);
  //
  const { setModuleData } = useModuleContext(); // Use context to set module data
  //
  const userRole = sessionStorage.get("user_role");
  const [isAdmin, setIsAdmin] = useState(false);
  //const token = sessionStorage.get("HS_token");
  // const decoded = jwtDecode(token);

  useEffect(() => {
    if (userRole === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);
  //
  return (
    <div className="landing">
      <div className="AppBarDiv">
        <ResponsiveAppBar />
      </div>
      <div className="landingDisplay">
        <Spin spinning={loading}>
          <Routes>
            <Route
              path="/"
              element={
                <div className="AppsContainer">
                  {modules && modules.length > 0 ? (
                    modules.map((module) => (
                      <div key={module.id}>
                        {CheckUserModuleAccess(module.id) ? (
                          <NavLink
                            to={module.link || "#"} // Ensure a valid link is passed
                            onClick={() =>
                              setModuleData(module.id, module.link)
                            }
                            // Uncomment if needed
                            // state={{
                            //   moduleId: module.id,
                            //   moduleLink: module.link,
                            // }}
                          >
                            <div className="AppCard">
                              {module.iconUrl ? (
                                <img
                                  className="AppIcon"
                                  src={module.iconUrl}
                                  alt={module.name || "Module Icon"}
                                />
                              ) : (
                                <div className="AppIconPlaceholder">
                                  No Icon
                                </div> // Fallback if no icon
                              )}
                              <h3 className="AppName">{module.name}</h3>
                            </div>
                          </NavLink>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <div>No modules available.</div>
                  )}

                  <NavLink to="my_tasks">
                    <div key={module.id}>
                      <div className="AppCard">
                        <img
                          className="AppIcon"
                          src={
                            "https://res.cloudinary.com/dodzmgyxj/image/upload/v1731601076/to-do-list_-_Copy_z4vi6p.gif"
                          }
                          alt={"My Todo"}
                        ></img>
                        <h3 className="AppName">My Tasks</h3>
                      </div>
                    </div>
                  </NavLink>

                  {process.env.REACT_APP_NODE_ENV == "development" ? (
                    <NavLink to="demo1">
                      <div key={module.id}>
                        <div className="AppCard">
                          <img
                            className="AppIcon"
                            src={
                              "https://res.cloudinary.com/dodzmgyxj/image/upload/v1731601076/to-do-list_-_Copy_z4vi6p.gif"
                            }
                            alt={"My Todo"}
                          ></img>
                          <h3 className="AppName">Demo1</h3>
                        </div>
                      </div>
                    </NavLink>
                  ) : null}
                </div>
              }
            ></Route>

            <Route path="profile" element={<Profile />}></Route>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="setting/*" element={<Settings />} />
            <Route path="usermng/*" element={<UserMng />} />
            <Route path="inventory/*" element={<Inventory />} />
            <Route path="discuss" element={<Discuss />} />
            <Route path="customermng/*" element={<CustomerMng />} />
            <Route path="suppliermng/*" element={<SupplierMng />} />
            <Route path="document/*" element={<Documentations />} />
            <Route path="profile_edit" element={<ProfileEdit />} />
            <Route path="loghistory" element={<LogHistory />} />
            <Route path="salesmng/*" element={<Sales />} />
            <Route path="my_tasks" element={<MyTasks />} />
            <Route path="mail_sync/*" element={<MailSync />} />
            <Route path="projectmng/*" element={<Project />} />
            <Route path="purchasemng/*" element={<Purchase />} />
            <Route path="accountingmng/*" element={<Accounting />} />
            {/* demos */}
            <Route path="demo1/*" element={<Demo1 />} />
          </Routes>
        </Spin>
      </div>
      {/* <Outlet /> */}
    </div>
  );
};

export default Landing;
