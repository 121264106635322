import React, { useState } from "react";
import "./ResetPassword.css";
//
import { useParams } from "react-router-dom";
//notifications
import {
  notifyError,
  notifySuccess,
} from "../../../Components/Notifications/Notifications";
//
//ant design
import { Spin } from "antd";
//api methods
import { ResetUserPassword } from "../../../API/User/userAPI";
//
import { error } from "console";

const RestPassword = () => {
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //
  //tenant uuid from the url
  type Params = {
    token: string; // Or use '?' if it might be optional
  };
  let { token } = useParams<Params>();

  //states
  const [UserNewPassword, setUserNewPassword] = useState("");
  const [UserConfirmPassword, setUserConfirmPassword] = useState("");
  //method to handle reset password
  const HandleResetPassword = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (UserNewPassword != UserConfirmPassword) {
        throw new Error("Password Not Confiremd");
      }
      const result = await ResetUserPassword(token as String, UserNewPassword);
      notifySuccess(result.data);
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  //
  return (
    <div className="resetMain">
      <div className="resetContainer">
        <Spin spinning={loading}>
          <h1>Rest Password</h1>
          <form onSubmit={HandleResetPassword}>
            <label>
              New Password
              <input
                type="password"
                onChange={(e) => setUserNewPassword(e.target.value)}
                required
              ></input>
            </label>
            <label>
              Confirm Password
              <input
                type="password"
                onChange={(e) => setUserConfirmPassword(e.target.value)}
                required
              ></input>
            </label>
            <button type="submit">Reset Password</button>
          </form>
        </Spin>
      </div>
    </div>
  );
};

export default RestPassword;
