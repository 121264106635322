//### Core
import React, { useEffect, useState } from "react";

//### Third Party
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

//ant design
import { Tooltip } from "antd";
import { Tabs as AntTabs } from "antd";
import { Select } from "antd";
import { Button, Form, Input, Space } from "antd";
import { Spin } from "antd";
import { Divider } from "antd";
import { Modal } from "antd";
import { Drawer } from "antd";
// ant design icons
import { CloudUploadOutlined } from "@ant-design/icons";

//### Absolute
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../Components/Notifications/Notifications";
//api methods
import { addCustomer } from "../../../API/Customer/customerAPI";

//###interfaces
// interface default_type {
//   id: Number;
//   name: String;
// }
//

const CreateView = () => {
  //fields
  const [customerID, setCustomerID] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  //
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);

  //### Methods
  //default methods

  //### CRUD Methods
  const handleAddModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      var req = await addCustomer({
        Name: name,
        Mobile: mobile,
        Email: email,
        Address: address,
        Record_Note: recordNote,
      });
      notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={handleAddModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New Customer Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>
            <div className="form-row">
              <label>
                Name:
                <input
                  type="text"
                  placeholder="e.g Unit"
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </label>
              <label>
                Mobile:
                <input
                  type="tel"
                  placeholder="e.g Unit"
                  onChange={(event) => setMobile(event.target.value)}
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                Email:
                <input
                  type="email"
                  placeholder="Email Address"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </label>
              <label>
                Address:
                <textarea
                  placeholder="Enter Address"
                  onChange={(event) => setAddress(event.target.value)}
                />
              </label>
            </div>
            <ReactQuill theme="snow" onChange={setRecordNote} />
          </div>
        </Spin>
      </form>

      {/* modals */}
    </div>
  );
};

export default CreateView;
