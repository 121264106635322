//### Core
import React, { useEffect, useState } from "react";

//### Third Party
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Tooltip } from "antd";
import { Tabs as AntTabs } from "antd";
import { Select } from "antd";
import { Button, Form, Input, Space } from "antd";
import { Spin } from "antd";
import { Divider } from "antd";
import { Modal } from "antd";
import { Drawer } from "antd";
// ant design icons
import { CloudUploadOutlined } from "@ant-design/icons";

//### Absolute
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../../Components/Notifications/Notifications";
//api methods
import { addProductSubCategories } from "../../../../API/Inventory/product/productsubcategoriesAPI ";
import { getAllProductCategories } from "../../../../API/Inventory/product/productcategoriesAPI";
//interface
// interface default_type {
//   id: Number;
//   name: String;
// }
// //

const CreateView = () => {
  //fields
  const [name, setName] = useState("");
  const [uuid, setUUID] = useState("");
  const [description, setDescription] = useState("");
  const [parentCategorieID, setParentCategorieID] = useState("");
  //default
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //data fields
  const [productCategorieData, setProductCategorieData] = useState([]);

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //### Methods
  //default methods
  const getWarehoseList = async () => {
    try {
      const res = await getAllProductCategories();
      setProductCategorieData(res.data);
      console.log(res.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };
  //
  useEffect(() => {
    getWarehoseList();
  }, []);

  //###Constraints and onchanges

  //###CRUD Methods
  const handleAddModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      var req = await addProductSubCategories({
        ParentCategorieID: parentCategorieID,
        Name: name,
        UUID: uuid,
        Description: description,
        //
        Record_Note: recordNote,
      });
      notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={handleAddModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New Product Sub-Categorie Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Select a parent category"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Parent Categorie:
                </Tooltip>
                <Select
                  showSearch
                  placeholder="Select a Category"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setParentCategorieID(value)}
                  options={productCategorieData.map((pc: any) => ({
                    value: pc.id,
                    label: pc.name,
                  }))}
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter product subcategory name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Name:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g. Electronics-Accessories"
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter product subcategory name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  UUID:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g. Electronics-Accessories"
                  onChange={(event) => setUUID(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter product subcategory name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Description:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g. Electronics-Accessories"
                  onChange={(event) => setDescription(event.target.value)}
                  required
                />
              </label>
            </div>

            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              onChange={setRecordNote}
            />
          </div>
        </Spin>
      </form>
    </div>
  );
};

export default CreateView;
