//### Core
import React, { useEffect, useState } from "react";

//### Third Party
import { useLocation } from "react-router-dom";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Tooltip } from "antd";
import { Tabs as AntTabs } from "antd";
import { Select } from "antd";
import { Button, Form, Input, Space } from "antd";
import { Spin } from "antd";
import { Divider } from "antd";
import { Modal } from "antd";
import { Drawer } from "antd";

// ant design icons
import { CloudSyncOutlined } from "@ant-design/icons";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

//### Absolute
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
  notifyWarning,
} from "../../../../Components/Notifications/Notifications";
//api methods
import {
  getOneCheckOut,
  checkIN,
} from "../../../../API/Inventory/checkin_checkout/checkin_checkout_api";
import { getAllProduct } from "../../../../API/Inventory/product/productAPI";
import { getAllUser } from "../../../../API/User/userAPI";

//interface
// interface default_type {
//   id: Number;
//   name: String;
// }
// //

const UpdateView = () => {
  //# Variables
  const location = useLocation();
  const [form] = Form.useForm(); // ✅ Define form instance
  //fields
  const [routeRecordID, setRouteRecordID] = useState(
    location.state?.routeRecordId
  );
  const [to, setTo] = useState("");
  const [reference, setReference] = useState("");
  const [description, setDescription] = useState("");
  const [checkout_date, setCheckout_Date] = useState("");
  const [checkin_date, setCheckin_Date] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [productLine, setProductLine] = useState<
    Array<{ productID: Number; quantity: Number; uom: String; remark: String }>
  >([]);
  // const [productLine, setProductLine] = useState([
  //   { productID: "", quantity: "", uom: "", remark: "" },
  // ]);
  //
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //data fields
  const [usersData, setUsersData] = useState([]);
  const [productsData, setProductsData] = useState([]);

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //### Methods
  //default methods
  const load_mutable_data = async () => {
    if (!routeRecordID) {
      notifyError("Invalid Record ID");
      return;
    }
    try {
      var response: any = await getOneCheckOut(routeRecordID);
      console.log("API Response:", response.data);
      const getProductList = await getAllProduct();
      const getUserList = await getAllUser();
      console.log("Product List:", getProductList.data);
      //var warehouseList = await getAllWarehouse();
      // Check if the fetched record ID matches the session record ID
      if (response.data.id !== routeRecordID) {
        notifyError("Record ID mismatch. Unauthorized access.");
        return;
      }

      console.log(response.data);
      setTo(response.data.to);
      setReference(response.data.reference);
      setDescription(response.data.description);
      setRequestedBy(response.data.requestedByUserID);
      setApprovedBy(response.data.approvedByUserID);
      if (response.data.checkout_date) {
        const formattedDate = new Date(response.data.checkout_date)
          .toISOString()
          .split("T")[0];
        setCheckout_Date(formattedDate);
      }
      if (response.data.checkin_date) {
        const formattedDate = new Date(response.data.checkin_date)
          .toISOString()
          .split("T")[0];
        setCheckin_Date(formattedDate);
      }

      //list
      setProductsData(getProductList.data);
      setUsersData(getUserList.data);
      //
      //
      // const formattedProductLine = (response.data.ProductLines || []).map(
      //   (line: any) => ({
      //     productID: line.productID,
      //     quantity: line.quantity,
      //     remark: line.remark,
      //     // productID: 1,
      //     // quantity: 1,
      //     // UOM: "",
      //     // remark: "1",
      //   })
      // );
      // Check if ProductLines exists in the response
      // if (response.data.ProductLines) {
      // setProductLine(
      //   (response.data.ProductLines || []).map(
      //     (att: {
      //       productID: number;
      //       quantity: number;
      //       // uom: string;
      //       remark: string;
      //     }) => ({
      //       productID: att.productID,
      //       quantity: att.quantity,
      //       uom: "",
      //       remark: att.remark,
      //     })
      //   )
      // );
      const formattedProductLine = (response.data.ProductLines || []).map(
        (att: { productID: number; quantity: number; remark: string }) => ({
          productID: att.productID,
          quantity: att.quantity,
          uom: "", // Ensure UOM is properly included
          remark: att.remark,
        })
      );

      setProductLine(formattedProductLine);

      // ✅ Explicitly update the form values
      form.setFieldsValue({
        attributes: formattedProductLine,
      });
      console.log("Product Lines:", productLine);
      // } else {
      //   notifyWarning("ProductLines is missing in the API response");
      // }

      setRecordNote(response.data.recordNote);
      setCreatedAt(response.data.createdAt);
      setCreatedByName(response.data.CreatedByUser.fullName);
      setUpdatedAt(response.data.updatedAt);
      setUpdatedByName(response.data.UpdatedByUser.fullName);
    } catch (error: any) {
      console.log(error);
      handleCustomError(error);
    }
  };
  useEffect(() => {
    load_mutable_data();
  }, []);

  //###Constraints and onchanges

  //###CRUD Methods
  const HandleUpdateModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      // var req = await update_One_Warehouse_Location({
      //   ID: routeRecordID,
      //   Name: name,
      //   Warehouse_ID: warehouseID,
      //   Record_Note: recordNote,
      // });
      // notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  const HandleCheckIn = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);

      var req = await checkIN({
        ID: routeRecordID,
      });
      notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={HandleUpdateModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>Update Checkout Form</h4>
              <Button
                type="primary"
                // htmlType="submit"
                onClick={HandleCheckIn}
                icon={<CloudSyncOutlined />}
              >
                Returned
              </Button>

              {/* <Button
                type="primary"
                htmlType="submit"
                icon={<CloudSyncOutlined />}
              >
                Update
              </Button> */}
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter product category name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  To:
                </Tooltip>
                <input
                  type="text"
                  placeholder=""
                  value={to}
                  onChange={(event) => setTo(event.target.value)}
                  required
                />
              </label>
              <label>
                <Tooltip
                  title="Enter product category name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Ref:
                </Tooltip>
                <input
                  type="text"
                  placeholder="010101"
                  value={reference}
                  onChange={(event) => setReference(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Planned return date"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Checked Out Date:
                </Tooltip>
                <Input
                  type="date"
                  value={checkout_date}
                  onChange={(e) => setCheckout_Date(e.target.value)}
                ></Input>
              </label>
              <label>
                <Tooltip
                  title="Planned return date"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Checked In Date:
                </Tooltip>
                <Input
                  type="date"
                  value={checkin_date}
                  onChange={(e) => setCheckin_Date(e.target.value)}
                ></Input>
              </label>
            </div>
            <div className="form-row">
              <AntTabs
                defaultActiveKey="1"
                items={[
                  {
                    key: "1",
                    label: "Product Lines",
                    children: (
                      <>
                        <Form
                          form={form} // ✅ Ensure form is connected
                          name="dynamic_form_nest_item"
                          style={{ maxWidth: 600 }}
                          autoComplete="off"
                          initialValues={{
                            attributes: productLine,
                          }}
                          onValuesChange={(changedValues, allValues) => {
                            if (allValues.attributes) {
                              setProductLine(allValues.attributes);
                              console.log(
                                "Updated attributes:",
                                allValues.attributes
                              );
                              console.log("Product Lines:", productLine);
                            }
                          }}
                        >
                          <Form.List name="attributes">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Space
                                    key={key}
                                    style={{
                                      display: "flex",
                                      marginBottom: 8,
                                    }}
                                    align="baseline"
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "productID"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Add Product",
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        placeholder="Select a Product"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        // onChange={(value) =>
                                        //   setProductUOMID(value)
                                        // }
                                        options={productsData.map((p: any) => ({
                                          value: p.id,
                                          label: p.name,
                                        }))}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "quantity"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input
                                        type="Number"
                                        placeholder="Quantity"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "uom"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="UOM" readOnly />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "remark"]}
                                      rules={[
                                        {
                                          // required: fa,
                                          // message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Remark" />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Line
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>
                      </>
                    ),
                  },
                  {
                    key: "2",
                    label: "Requester",
                    children: (
                      <>
                        <div className="form-row">
                          <label>
                            <Tooltip
                              title="Enter product category name"
                              color="#333333"
                              placement="bottomLeft"
                            >
                              Requested By:
                            </Tooltip>
                            <Select
                              showSearch
                              placeholder="Select User"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={requestedBy}
                              onChange={(value) => setRequestedBy(value)}
                              options={usersData.map((u: any) => ({
                                value: u.id,
                                label: u.fullName,
                              }))}
                            />
                          </label>
                        </div>
                      </>
                    ),
                  },
                  {
                    key: "3",
                    label: "Approved",
                    children: (
                      <>
                        <div className="form-row">
                          <label>
                            <Tooltip
                              title="Enter product category name"
                              color="#333333"
                              placement="bottomLeft"
                            >
                              Approved By:
                            </Tooltip>
                            <Select
                              showSearch
                              placeholder="Select User"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={approvedBy}
                              onChange={(value) => setApprovedBy(value)}
                              options={usersData.map((u: any) => ({
                                value: u.id,
                                label: u.fullName,
                              }))}
                            />
                          </label>
                        </div>
                      </>
                    ),
                  },
                ]}
              ></AntTabs>
            </div>
            <div className="form-row">
              <label>Description:</label>
              <textarea
                placeholder="Enter Description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              value={recordNote}
              onChange={setRecordNote}
            />

            <div className="form-row">
              <div className="recordAuthor">
                <label className="productlabels">
                  Created By:
                  <input
                    type="text"
                    placeholder=""
                    value={createdByName}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Created At:
                  <input
                    type="text"
                    placeholder=""
                    value={createdAt}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Last Updated By:
                  <input
                    type="text"
                    placeholder=""
                    value={updatedByName}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Last Updated At :
                  <input
                    type="text"
                    placeholder=""
                    value={updatedAt}
                    readOnly
                  ></input>
                </label>
              </div>
            </div>
          </div>
        </Spin>
      </form>

      {/* modals */}
    </div>
  );
};

export default UpdateView;
