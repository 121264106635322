// ###Build in imports
import React, { useEffect, useState } from "react";

// ###external imports
//
import { sessionStorage } from "es-storage";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// Ant Design
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Button, Select } from "antd";
import { Tooltip } from "antd";
import { Spin } from "antd";
import { Checkbox } from "antd";
//antd tabs
import { Tabs as AntTabs } from "antd";
//antd icons
import { CloudUploadOutlined } from "@ant-design/icons";

// ###file imports
//styles
import "./CreateView.css";
//notification
import {
  notifySuccess,
  notifyWarning,
  notifyError,
  notifyInfo,
} from "../../../Components/Notifications/Notifications";
//api methods
import { addUser } from "../../../API/User/userAPI";
import { getAllDepartment } from "../../../API/User/department/departmentAPI";
import { getAllTenantModule } from "../../../API/moduleAPI";
import Department from "../Department/Department";

//

//
const plainOptions = [
  { label: "Create", value: "create" },
  { label: "Read", value: "read" },
  { label: "Update", value: "update" },
  { label: "Delete", value: "delete" },
];

const CreateView = () => {
  //user states
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [confirmUserPassword, setConfirmUserPassword] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userRole, setUserRole] = useState("");
  const [departmentID, setDepartmentID] = useState("");
  // State initialization for permissions
  const [permissions, setPermissions] = useState<Array<any>>([]);
  //note for the current recors
  const [RecordNote, setRecordNote] = useState("");
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //notice methods
  //
  //api data states
  const [TenantModuleList, setTenantModuleList] = useState<any[]>([]);
  const [departmentData, setDepartmentData] = useState([]);

  // ###methods
  //inital data methods
  const GetTenantModules = async () => {
    try {
      var req: any = await getAllTenantModule();
      setTenantModuleList(req.data);
      console.log(req.data);

      //console.log("session=" + sessionStorage.getObject("user_rbac"));
      // console.log("session=" + sessionStorage.getObject("user_rbac").length);
    } catch (err: any) {
      console.log(err);
      //notify_Error(err.response.data);
    }
  };

  const GetDepartments = async () => {
    try {
      var req: any = await getAllDepartment();
      setDepartmentData(req.data);

      //console.log("session=" + sessionStorage.getObject("user_rbac"));
      // console.log("session=" + sessionStorage.getObject("user_rbac").length);
    } catch (err: any) {
      console.log(err);
      //notify_Error(err.response.data);
    }
  };

  useEffect(() => {
    // Define your async function to call the API
    const fetchData = async () => {
      await GetTenantModules();
      await GetDepartments();
    };
    fetchData(); // Call the async function
  }, []);
  //

  // #event handler methods

  // Helper to retrieve selected options for a specific module
  const getCheckedValues = (moduleId: number) => {
    const module = permissions.find((item) => item.moduleID === moduleId);
    if (module) {
      const checkedValues = [];
      if (module.Create) checkedValues.push("create");
      if (module.Read) checkedValues.push("read");
      if (module.Update) checkedValues.push("update");
      if (module.Delete) checkedValues.push("delete");
      return checkedValues;
    }
    return [];
  };

  //
  const onChange = (moduleId: number, checkedValues: any) => {
    setPermissions((prevData: any) => {
      const updatedData = prevData.map((item: any) => {
        if (item.moduleID === moduleId) {
          return {
            ...item,
            Create: checkedValues.includes("create"),
            Read: checkedValues.includes("read"),
            Update: checkedValues.includes("update"),
            Delete: checkedValues.includes("delete"),
          };
        }
        return item;
      });

      // If moduleId is not found in the existing data, add it
      if (!prevData.some((item: any) => item.moduleID === moduleId)) {
        updatedData.push({
          moduleID: moduleId,
          Create: checkedValues.includes("Create"),
          Read: checkedValues.includes("Read"),
          Update: checkedValues.includes("Update"),
          Delete: checkedValues.includes("Delete"),
        });
      }

      console.log(
        "Updated permissions for module",
        moduleId,
        "=",
        checkedValues
      );
      console.log("RBACNewUserData", updatedData);

      return updatedData;
    });
    // console.log("Updated permissions for module", moduleId, "=", checkedValues);
    // console.log("RBACNewUserData", RBACNewUserData);
  };
  //

  //add user methods
  const HandleAddUser = async (event: any) => {
    event.preventDefault();
    try {
      setLoading(true);

      if (userPassword !== confirmUserPassword) {
        // Show warning and exit function
        notifyWarning("Passwords do not match!");
        return; // This should exit the function immediately
      }

      if (!userName || !userEmail || !userPhone || !userPassword || !userRole) {
        notifyWarning("All fields are required!");
        return; // Exit if any required field is missing
      }

      var req = await addUser({
        Name: userName,
        Email: userEmail,
        Phone: userPhone,
        Password: userPassword,
        Role: userRole,
        RBAC: permissions,
        DepartmentID: departmentID,
        //
        Record_Note: RecordNote,
        CreatedBy: sessionStorage.get("user_name"),
      });

      //GetUsers();
      notifySuccess(req.data.message || "");
      // Notify user of email status
      const emailStatusMessage = req.data.emailSent
        ? "Email reminder sent successfully."
        : "Failed to send email reminder.";
      notifyInfo(`Email Reminder Status: ${emailStatusMessage}`);
      console.log(req);
    } catch (error: any) {
      // notify_error(err.response.data);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        notifyError(error.response.data || "");
        console.log(error);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request);
        console.log(error.request);
      } else if (error.message) {
        // Something happened in setting up the request that triggered an Error

        notifyError(error.message);
        console.log("Error", error.message);
      } else {
        // console.log(err);
        notifyError(error);
      }
    } finally {
      setLoading(false); // Set loading to false after request completion or error
    }
  };

  // ui controller methods
  //

  return (
    <div className="ModuleCreateView">
      <form className="ModuleCreateViewForm" onSubmit={HandleAddUser}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New User Form</h4>
              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>

            <div className="form-row">
              <label className="productlabels">
                <Tooltip
                  title="User Full Name!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Name:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g Alex Morgan"
                  onChange={(event) => setUserName(event.target.value)}
                  minLength={2}
                  maxLength={50}
                  required
                />
              </label>

              <label className="productlabels">
                <Tooltip
                  title="Used to login to the system. Using a proper email address allows users to receive email notice!"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Email
                </Tooltip>
                <input
                  type="email"
                  placeholder="example@example.com"
                  onChange={(event) => setUserEmail(event.target.value)}
                  minLength={2}
                  maxLength={125}
                  required
                />
              </label>
            </div>

            <div className="form-row">
              <label className="productlabels">
                Work Phone
                <input
                  type="tel"
                  placeholder="+251-00-00-00-00"
                  onChange={(event) => setUserPhone(event.target.value)}
                  minLength={10}
                  maxLength={15}
                />
              </label>

              <label className="productlabels">
                Role:
                <select
                  name="selectedRole"
                  onChange={(event) => setUserRole(event.target.value)}
                  required
                >
                  <option value=""></option>
                  <option value="Internal_User">Internal User</option>
                </select>
              </label>
            </div>

            <div className="form-row">
              <label className="productlabels">
                <Tooltip
                  title="Your password should be at least 8 characters, include both uppercase and lowercase letters, and have at least one number or special character."
                  color="#333333"
                  placement="bottomLeft"
                >
                  New Password
                </Tooltip>
                <Input.Password
                  placeholder="Enter your new password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  onChange={(event) => setUserPassword(event.target.value)}
                  minLength={8}
                  maxLength={128}
                  required
                />
              </label>

              <label className="productlabels">
                <Tooltip
                  title="Please enter the same password as above to confirm."
                  color="#333333"
                  placement="bottomLeft"
                >
                  Confirm Password
                </Tooltip>
                <Input.Password
                  placeholder="Re-enter your new password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  onChange={(event) =>
                    setConfirmUserPassword(event.target.value)
                  }
                  minLength={8}
                  maxLength={128}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label className="productlabels">
                <Tooltip
                  title="Enter the user department"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Department:
                </Tooltip>
                <Select
                  showSearch
                  placeholder="Select Department"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setDepartmentID(value)}
                  options={departmentData.map((u: any) => ({
                    value: u.id,
                    label: u.name,
                  }))}
                />
              </label>
            </div>
            <div className="form-row">
              <AntTabs
                defaultActiveKey="1"
                items={[
                  {
                    key: "1",
                    label: "Access Controle",
                    children: (
                      <>
                        <h3>User Role Based Access Control</h3>
                        <div className="UserModuleRBAC">
                          {TenantModuleList.map((value: any) => (
                            <div key={value.id} className="moduleWrapper">
                              <h5>{value.Module.name}</h5>
                              <Checkbox.Group
                                options={plainOptions}
                                onChange={(checkedValues) =>
                                  onChange(value.Module.id, checkedValues)
                                }
                                value={getCheckedValues(value.Module.id)}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="rbacInfo">
                          <h4 style={{ color: "orange" }}>
                            Understanding The System Role Based Access Control
                            (RBAC)
                          </h4>
                          <p>
                            <strong>Read Permission:</strong> Users must have
                            read access to view and interact with a module.
                            Without this permission, users cannot access the
                            module at all.
                          </p>
                          <p>
                            <strong>Create Permission:</strong> Users with
                            create access can add new records to the module.
                            This permission alone does not allow viewing or
                            modifying existing records.
                          </p>
                          <p>
                            <strong>Update Permission:</strong> Users can modify
                            existing records within the module if they have
                            update access. This includes editing or updating
                            data.
                          </p>
                          <p>
                            <strong>Delete Permission:</strong> Users with
                            delete access can remove records from the module.
                            This action is permanent and should be used
                            cautiously.
                          </p>
                          <p style={{ color: "red" }}>
                            <strong>Important:</strong> This RBAC feature is
                            currently under development. If you need to update a
                            user's RBAC settings urgently or have any questions,
                            please contact me directly.
                          </p>
                        </div>
                      </>
                    ),
                  },
                ]}
              ></AntTabs>
            </div>
          </div>
          <ReactQuill theme="snow" onChange={setRecordNote} />
        </Spin>
      </form>
    </div>
  );
};

export default CreateView;
