import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const Add_One_UOM = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_UOM_ADD_ONE}`,
      {
        name: props.Name,
        uom_CategoryID: props.UOM_CategoryID,
        //
        recordNote: props.Record_Note,
        createdByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getOneUOM = async (ID: String) => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_UOM_GET_ONE}/${ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllUOMS = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_UOM_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateOneUOM = async (props: any) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_UOM_UPDATE_ONE}/${props.ID}`,
      {
        name: props.Name,
        uom_CategoryID: props.UOM_CategoryID,
        //
        recordNote: props.Record_Note,
        updatedByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteSingeleUOM = async (ID: String | Number) => {
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_UOM_DELETE_ONE}/${ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export { Add_One_UOM, getOneUOM, getAllUOMS, updateOneUOM, deleteSingeleUOM };
