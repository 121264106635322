import React, { useState, useEffect } from "react";
import "./SignIn.css";
//contect
import { useAuthContext } from "../../../hooks/authcontext";
//context
import { useTenantSubscriptionContext } from "../../../Contexts/TenantSubscriptionContext";
//notifications
import {
  notifySuccess,
  notifyError,
} from "../../../Components/Notifications/Notifications";
//ant design
import { Spin } from "antd";
import { Alert } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { Popover } from "antd";
//
import { authSignIn } from "../authentication";
import { useNavigate, useParams } from "react-router-dom";
//
import { getTenant } from "../../../API/Tenant/tenantAPI";
//
import habsyncLogo from "../../../assest/logo192.png";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
//
const SignIn = () => {
  //setion
  const [
    allow_users_reset_password_at_login,
    set_allow_users_reset_password_at_login,
  ] = useState(false);
  // set_allow_users_reset_password_at_login(
  //   sessionStorage.get("allow_users_reset_password_at_login")
  // );
  //contexts
  const { login } = useAuthContext();
  const { modules, addModule } = useTenantSubscriptionContext();
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  const [logoLoading, setLogoLoading] = React.useState<boolean>(false);

  let { tenantUUID } = useParams();
  const [TenantName, setTenantName] = useState("");
  const [TenantLogo, setTenantLogo] = useState("");
  //

  //
  useEffect(() => {
    const fetchTenantData = async () => {
      try {
        setLogoLoading(true);
        const response = await getTenant(tenantUUID);

        setTenantName(response.data.name);
        setTenantLogo(response.data.logo);
        set_allow_users_reset_password_at_login(
          response.data.TenantPreference.allow_users_reset_password_at_login ||
            false
        );
      } catch (err: any) {
        // console.error(err);
        setLogoLoading(false);
        notifyError(`Message:${err.message}`);
        //throw err;
      } finally {
        setLogoLoading(false);
      }
    };

    fetchTenantData();
  }, []);
  //
  const navigate = useNavigate();
  //
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState<string>("");
  //
  const HandleSignIn = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      var call: any = await authSignIn({
        // tenantID: tenantId,
        tenantUUID: tenantUUID,
        email: Email,
        password: Password,
      });
      // console.log(call);
      if (!call) {
        notifyError("Server error");
      } else {
        notifySuccess(call.data.message);
        // navigate("/landing", { replace: true });
        // Assuming login is successful, set isAuthenticated to true
        // setTimeout(() => {
        //   login().then(() => {
        //     navigate("/landing", { replace: true });
        //   });

        //   //navigate("/landing", { replace: true });
        // }, 2000); // Delay navigation to allow the toast to display
        // First update the auth state
        await login();
        navigate("/landing", { replace: true });
        window.location.reload();
      }
      addModule(call.data.TenantSubModules.map((x: any) => x.Module));
    } catch (err: any) {
      // console.log(err);
      if (!err.response.data) {
        notifyError(err.message);
      } else if (err.response.data) {
        notifyError(err.response.data || err);
      } else {
        notifyError(err);
      }
      // notify(err.response.data || err);
      // notify(err);
    } finally {
      setLoading(false); // Set loading to false after request completion or error
    }
  };
  //
  const handleResetPassword = () => {
    navigate(`/forgot-password/${tenantUUID}`, { replace: false });
  };
  //
  return (
    <div className="signIn">
      <div className="signIn_Form_div">
        <div className="signin_company">
          <Spin spinning={logoLoading}>
            <h2 className="login_company_name">HabSync</h2>
            <div className="logo-container">
              <img
                className="logo"
                src={TenantLogo || habsyncLogo}
                alt="logo"
              />
            </div>
          </Spin>
          <h2 className="login_company_name">{TenantName || "Welcome Back"}</h2>
          <p className="login-subtitle">
            Please enter your credentials to continue
          </p>
        </div>

        <div className="form-container">
          <Spin spinning={loading}>
            <form
              id="Shared_Tenant_User_Login_Form"
              onSubmit={HandleSignIn}
              className="users_signin_form"
            >
              <div className="form-group">
                <label className="input-label">Email Address</label>
                <Input
                  className="signin-input"
                  type="email"
                  prefix={<MailOutlined />}
                  placeholder="Enter your email"
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label className="input-label">Password</label>
                <Input.Password
                  className="signin-input"
                  prefix={<LockOutlined />}
                  placeholder="Enter your password"
                  onChange={(event) => setPassword(event.target.value)}
                  required
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </div>

              <div className="signin-actions">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-button"
                  block
                  size="large"
                >
                  Sign In
                </Button>

                {allow_users_reset_password_at_login && (
                  <Button
                    type="link"
                    className="forgot-password-link"
                    onClick={handleResetPassword}
                  >
                    Forgot Password?
                  </Button>
                )}
              </div>
            </form>
          </Spin>
        </div>

        <div className="signin-footer">
          <p className="copyright">
            © 2024 HabSync Group. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
