//### Core
import React, { useEffect, useState } from "react";

//### Third Party
import { useLocation } from "react-router-dom";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Spin } from "antd";
import { Tooltip } from "antd";
import { Button } from "antd";
// ant design icons
import { CloudSyncOutlined } from "@ant-design/icons";

//### Absolute
import {
  handleCustomError,
  notifyError,
  notifySuccess,
} from "../../../../Components/Notifications/Notifications";
//api methods
import {
  getOneProductCategorie,
  updateSingeleProductCategory,
} from "../../../../API/Inventory/product/productcategoriesAPI";

//###interfaces

const UpdateView = () => {
  const location = useLocation();
  //fields
  const [routeRecordID, setRouteRecordID] = useState(
    location.state?.routeRecordId
  );
  const [name, setName] = useState("");
  const [uuid, setUUID] = useState("");
  const [description, setDescription] = useState("");
  //
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);

  //### Methods
  //default methods
  const load_mutable_data = async () => {
    if (!routeRecordID) {
      notifyError("Invalid Record ID");
      return;
    }
    try {
      var response: any = await getOneProductCategorie(routeRecordID);
      // Check if the fetched record ID matches the session record ID
      if (response.data.id !== routeRecordID) {
        notifyError("Record ID mismatch. Unauthorized access.");
        return;
      }

      setName(response.data.name);
      setUUID(response.data.uuid);
      setDescription(response.data.description);
      //
      setRecordNote(response.data.recordNote);
      setCreatedAt(response.data.createdAt);
      setCreatedByName(response.data.CreatedByUser?.fullName);
      setUpdatedAt(response.data.updatedAt);
      setUpdatedByName(response.data.UpdatedByUser?.fullName);
    } catch (error: any) {
      handleCustomError(error);
    }
  };
  useEffect(() => {
    load_mutable_data();
  }, []);

  //### CRUD Methods
  const HandleUpdateWarehouse = async (event: any) => {
    event.preventDefault();
    try {
      setLoading(true);
      //
      const req = await updateSingeleProductCategory({
        ID: routeRecordID,
        Name: name,
        UUID: uuid,
        Description: description,
        //
        Record_Note: recordNote,
      });
      // console.log(call);
      await load_mutable_data();
      notifySuccess(req.data.msg);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={HandleUpdateWarehouse}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>Update Product Category Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudSyncOutlined />}
              >
                Update
              </Button>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter product category name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Name:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g computers, furniture, laboratory equipment, etc"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter UUID "
                  color="#333333"
                  placement="bottomLeft"
                >
                  UUID:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g 01"
                  value={uuid}
                  onChange={(event) => setUUID(event.target.value)}
                  minLength={2}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>Description:</label>
              <textarea
                placeholder="Enter Product Category Description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>

            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              value={recordNote}
              onChange={setRecordNote}
            />

            <div className="form-row">
              <div className="recordAuthor">
                <label className="productlabels">
                  Created By:
                  <input
                    type="text"
                    placeholder=""
                    value={createdByName}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Created At:
                  <input
                    type="text"
                    placeholder=""
                    value={createdAt}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Last Updated By:
                  <input
                    type="text"
                    placeholder=""
                    value={updatedByName}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Last Updated At :
                  <input
                    type="text"
                    placeholder=""
                    value={updatedAt}
                    readOnly
                  ></input>
                </label>
              </div>
            </div>
          </div>
        </Spin>
      </form>

      {/* modals */}
    </div>
  );
};

export default UpdateView;
