import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addSupplier = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_SUPPLIER_ADD_ONE}`,
      {
        name: props.Name,
        mobile: props.Mobile,
        email: props.Email,
        address: props.Address,
        recordNote: props.Record_Note,
        createdByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getOneSupplier = async (ID: String) => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_SUPPLIER_GET_ONE}/${ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllSupplier = async () => {
  try {
    var req = await axios.get(`${process.env.REACT_APP_SUPPLIER_GET_ALL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteSingeleSupplier = async (props: any) => {
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_SUPPLIER_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateSingeleSupplier = async (props: any) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_SUPPLIER_UPDATE_ONE}/${props.ID}`,
      {
        name: props.Name,
        mobile: props.Mobile,
        email: props.Email,
        address: props.Address,
        recordNote: props.Record_Note,
        updatedByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  addSupplier,
  getOneSupplier,
  getAllSupplier,
  updateSingeleSupplier,
  deleteSingeleSupplier,
};
