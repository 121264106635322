//### Core
import React, { useEffect, useState } from "react";

//### Third Party
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Tooltip } from "antd";
import { Tabs as AntTabs } from "antd";
import { Select } from "antd";
import { Button, Form, Input, Space } from "antd";
import { Spin } from "antd";
import { Divider } from "antd";
import { Modal } from "antd";
import { Drawer } from "antd";
// ant design icons
import { CloudUploadOutlined } from "@ant-design/icons";

//### Absolute
//notification
import {
  handleCustomError,
  notifyError,
  notifySuccess,
} from "../../../../../Components/Notifications/Notifications";
//api methods
import { Add_One_UOM } from "../../../../../API/Inventory/uom/uom_api";
import { getAllUOM_Categories } from "../../../../../API/Inventory/uom/uom_category_api";

//interface

// interface default_type {
//   id: Number;
//   name: String;
// }

//

const CreateView = () => {
  //###variables
  //###fields
  //state fields
  const [Name, seName] = useState("");
  const [UOM_Category_ID, setUOM_Category_ID] = useState("");

  const [UOM_CategoryA, setUOM_CategoryA] = useState([]);
  //default
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //### Methods
  //default methods
  const getUOMCategoriesList = async () => {
    try {
      const res = await getAllUOM_Categories();
      console.log(res.data);
      setUOM_CategoryA(res.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };
  //
  useEffect(() => {
    getUOMCategoriesList();
  }, []);

  //### CRUD Method
  const handleAddModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await Add_One_UOM({
        Name: Name,
        UOM_CategoryID: UOM_Category_ID,
        //
        Record_Note: recordNote,
      });
      notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={handleAddModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New UOM Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>
            <div className="form-row">
              <label>
                Name:
                <input
                  type="text"
                  placeholder="e.g Unit"
                  onChange={(event) => seName(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                UOM Category:
                <Select
                  showSearch
                  placeholder="Select a UOM"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => setUOM_Category_ID(value)}
                  options={UOM_CategoryA.map((uomc: any) => ({
                    value: uomc.id,
                    label: uomc.name,
                  }))}
                />
              </label>
            </div>
            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              onChange={setRecordNote}
            />
          </div>
        </Spin>
      </form>

      {/* modals */}
    </div>
  );
};

export default CreateView;
