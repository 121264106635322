//### Core dependencies
import React, { useState, useEffect } from "react";


//### Third-party libraries
import { Route, Routes, useNavigate } from "react-router-dom";

//### Absolute imports
//pages
import ListView from "./ListView/ListView";
import CreateView from "./CreateView/CreateView";
import UpdateView from "./UpdateView/UpdateView";

const WarehouseMng = () => {
  //
  const navigate = useNavigate();
  //

  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h3
            className="moduleName"
            onClick={() => {
              navigate(".");
            }}
          >
            Warehouses
          </h3>
        </div>
        <div className="moduleMngActionDiv">
          <button
            className="moduleCreateBtn"
            onClick={() => {
              navigate("create");
            }}
          >
            New
          </button>
        </div>
      </div>

      <div className="moduleDisplay">
        <Routes>
          <Route index element={<ListView />} />
          <Route path="/create" element={<CreateView />} />
          <Route path="/update" element={<UpdateView />} />
          {/* <Route path="productEditREQUESTS" element={<ProductEditRequests />} />
          <Route path="productEditApproval" element={<ProductEditApproval />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default WarehouseMng;
