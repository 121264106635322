//### Core
import React, { useEffect, useState } from "react";

//### Third Party
import { useLocation } from "react-router-dom";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Spin } from "antd";
import { Tooltip } from "antd";
import { Button } from "antd";
// ant design icons
import { CloudSyncOutlined } from "@ant-design/icons";

//### Absolute
import {
  handleCustomError,
  notifyError,
  notifySuccess,
} from "../../../../Components/Notifications/Notifications";
//api methods
import {
  addWarehouse,
  getOneWarehouse,
  updateSingeleWarehouse,
} from "../../../../API/Inventory/warehouse/warehouseAPI";

//###interfaces

const UpdateView = () => {
  const location = useLocation();
  //fields
  const [routeRecordID, setRouteRecordID] = useState(
    location.state?.routeRecordId
  );
  const [warehouseID, setWarehouseID] = useState("");
  const [warehouseName, setWarehouseName] = useState("");
  const [warehouseAddress, setWarehouseAddress] = useState("");
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);

  //### Methods
  //default methods
  const load_mutable_data = async () => {
    if (!routeRecordID) {
      notifyError("Invalid Record ID");
      return;
    }
    try {
      var response: any = await getOneWarehouse(routeRecordID);
      // Check if the fetched record ID matches the session record ID
      if (response.data.id !== routeRecordID) {
        notifyError("Record ID mismatch. Unauthorized access.");
        return;
      }

      setWarehouseName(response.data.name);
      setWarehouseAddress(response.data.address);
      //
      setRecordNote(response.data.recordNote);
      setCreatedAt(response.data.createdAt);
      setCreatedByName(response.data.CreatedByUser.fullName);
      setUpdatedAt(response.data.updatedAt);
      setUpdatedByName(response.data.UpdatedByUser.fullName);
    } catch (error: any) {
      handleCustomError(error);
    }
  };
  useEffect(() => {
    load_mutable_data();
  }, []);

  //### CRUD Methods
  const HandleUpdateWarehouse = async (event: any) => {
    event.preventDefault();
    try {
      setLoading(true);
      //
      const req = await updateSingeleWarehouse({
        ID: routeRecordID,
        Name: warehouseName,
        Address: warehouseAddress,
        //
        Record_Note: recordNote,
      });
      // console.log(call);
      await load_mutable_data();
      notifySuccess(req.data.msg);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={HandleUpdateWarehouse}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>Update Warehouse Form</h4>

              <Button type="primary" htmlType="submit" icon={<CloudSyncOutlined />}>
                Update
              </Button>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter warehouse name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Name:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g Central Warehouse"
                  value={warehouseName}
                  onChange={(event) => setWarehouseName(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter the warehouse address"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Address:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g 123 Main Street, City"
                  value={warehouseAddress}
                  onChange={(event) => setWarehouseAddress(event.target.value)}
                  required
                />
              </label>
            </div>

            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              value={recordNote}
              onChange={setRecordNote}
            />

            <div className="form-row">
              <div className="recordAuthor">
                <label className="productlabels">
                  Created By:
                  <input
                    type="text"
                    placeholder=""
                    value={createdByName}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Created At:
                  <input
                    type="text"
                    placeholder=""
                    value={createdAt}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Last Updated By:
                  <input
                    type="text"
                    placeholder=""
                    value={updatedByName}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Last Updated At :
                  <input
                    type="text"
                    placeholder=""
                    value={updatedAt}
                    readOnly
                  ></input>
                </label>
              </div>
            </div>
          </div>
        </Spin>
      </form>

      {/* modals */}
    </div>
  );
};

export default UpdateView;
