import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addOneWarehouseLocation = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_WAREHOUSE_LOCATION_ADD_ONE}`,
      {
        name: props.Name,
        warehouse_id: props.Warehouse_ID,
        //
        recordNote: props.Record_Note,
        createdByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getOneWarehouseLocation = async (ID: String) => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_WAREHOUSE_LOCATION_GET_ONE}/${ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllWarehouseLocation = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_WAREHOUSE_LOCATION_GET_ALL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteOneWarehouseLocation = async (props: any) => {
  // alert(props.id)
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_WAREHOUSE_LOCATION_DELETE_ONE}/${props.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const update_One_Warehouse_Location = async (props: any) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_WAREHOUSE_LOCATION_UPDATE_ONE}/${props.ID}`,
      {
        name: props.Name,
        warehouse_id: props.Warehouse_ID,
        //
        recordNote: props.Record_Note,
        updatedByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  addOneWarehouseLocation,
  getOneWarehouseLocation,
  getAllWarehouseLocation,
  deleteOneWarehouseLocation,
  update_One_Warehouse_Location,
};
