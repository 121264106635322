import React from "react";
import "./App.css";
import { ModuleProvider } from "./Contexts/ModuleContext";
//auth
import ProtectedRoute from "./Components/ProtectedRoute";
//
//notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
//
import { Routes, Route } from "react-router-dom";
//pages
import ForgetPassword from "./pages/Authorization/ForgotPassword/ForgotPassword";
import RestPassword from "./pages/Authorization/ResetPassword/ResetPassword";
//import SignUp from "./pages/Authorization/SignUp/SignUp";
import SignIn from "./pages/Authorization/SignIn/SignIn";
import Landing from "./pages/Landing/Landing";
import DocViewer from "./modules/Inventory/MyDocViewer/MyDocViewer";
//
import NoPage from "./pages/NoPage/NoPage";
//

function App() {
  return (
    <div className="App">
      <ToastContainer />

      <Routes>
        <Route path="/:tenantUUID" element={<SignIn />} />
        <Route
          path="/forgot-password/:tenantUUID"
          element={<ForgetPassword />}
        />
        <Route path="/reset-password/:token" element={<RestPassword />} />

        {/* <Route
          path="/landing/*"
          element={
            authed ? (
              <ModuleProvider>
                <Landing />
              </ModuleProvider>
            ) : (
              <SignIn />
            )
          }
        /> */}

        <Route
          path="/landing/*"
          element={
            <ProtectedRoute>
              <ModuleProvider>
                <Landing />
              </ModuleProvider>
            </ProtectedRoute>
          }
        />

        <Route path="/docviewer" element={<DocViewer />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </div>
  );
}

export default App;
