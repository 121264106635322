import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//auth
import { AuthProvider } from "./hooks/authcontext";
//wrapper
import { BrowserRouter } from "react-router-dom";
import { TenantSubscriptionProvider } from "./Contexts/TenantSubscriptionContext";
//global css
import "./GlobalCss/Module/ModuleView.css";
//list view
import "./GlobalCss/Module/ListView/ListView.css";
//create view
import "./GlobalCss/Module/CreateView/CreateView.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>

  <BrowserRouter>
    <AuthProvider>
      <TenantSubscriptionProvider>
        <App />
      </TenantSubscriptionProvider>
    </AuthProvider>
  </BrowserRouter>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
