//### Core
import React, { useEffect, useState } from "react";

//### Third Party
import { useLocation } from "react-router-dom";
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Select, Tooltip } from "antd";
import { Spin } from "antd";
import { Button } from "antd";
// ant design icons
import { CloudSyncOutlined } from "@ant-design/icons";

//### Absolute
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
  notifyWarning,
} from "../../../../Components/Notifications/Notifications";
//api methods
import {
  get_One_Department,
  update_one_department,
} from "../../../../API/User/department/departmentAPI";
import { getAllUser } from "../../../../API/User/userAPI";

//interface
// interface default_type {
//   id: Number;
//   name: String;
// }
// //

const UpdateView = () => {
  //# Variables
  const location = useLocation();
  //fields
  const [routeRecordID, setRouteRecordID] = useState(
    location.state?.routeRecordId
  );
  const [name, setName] = useState("");
  const [managerID, setManagerID] = useState("");
  //
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  //data fields
  const [usersData, setUsersData] = useState([]);

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //### Methods
  //default methods
  const load_mutable_data = async () => {
    if (!routeRecordID) {
      notifyError("Invalid Record ID");
      return;
    }
    try {
      var response: any = await get_One_Department(routeRecordID);
      //console.log("API Response:", response.data);
      const getUserList = await getAllUser();
      //var warehouseList = await getAllWarehouse();
      // Check if the fetched record ID matches the session record ID
      if (response.data.id !== routeRecordID) {
        notifyError("Record ID mismatch. Unauthorized access.");
        return;
      }
      //list
      setUsersData(getUserList.data);
      //

      setName(response.data.name);
      setManagerID(response.data.managerUserID);

      //

      setRecordNote(response.data.recordNote);
      setCreatedAt(response.data.createdAt);
      setCreatedByName(response.data.CreatedByUser.fullName);
      setUpdatedAt(response.data.updatedAt);
      setUpdatedByName(response.data.UpdatedByUser.fullName);
    } catch (error: any) {
      console.log(error);
      handleCustomError(error);
    }
  };
  useEffect(() => {
    load_mutable_data();
  }, []);

  //###Constraints and onchanges

  //###CRUD Methods
  const HandleUpdateModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      //alert(NewProductWarehouseID);
      //set suppliers
      //
      var req = await update_one_department({
        ID: routeRecordID,
        Name: name,
        ManagerID: managerID,
        //
        Record_Note: recordNote,
      });
      notifySuccess(req.data);
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={HandleUpdateModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>Update Department Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudSyncOutlined />}
              >
                Update
              </Button>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter warehouse name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Name:
                </Tooltip>
                <input
                  type="text"
                  placeholder="e.g Central Warehouse"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter the warehouse address"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Manager:
                </Tooltip>
                <Select
                  showSearch
                  placeholder="Select User"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  value={managerID}
                  onChange={(value) => setManagerID(value)}
                  options={usersData.map((u: any) => ({
                    value: u.id,
                    label: u.fullName,
                  }))}
                />
              </label>
            </div>

            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              value={recordNote}
              onChange={setRecordNote}
            />

            <div className="form-row">
              <div className="recordAuthor">
                <label className="productlabels">
                  Created By:
                  <input
                    type="text"
                    placeholder=""
                    value={createdByName}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Created At:
                  <input
                    type="text"
                    placeholder=""
                    value={createdAt}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Last Updated By:
                  <input
                    type="text"
                    placeholder=""
                    value={updatedByName}
                    readOnly
                  ></input>
                </label>

                <label className="productlabels">
                  Last Updated At :
                  <input
                    type="text"
                    placeholder=""
                    value={updatedAt}
                    readOnly
                  ></input>
                </label>
              </div>
            </div>
          </div>
        </Spin>
      </form>

      {/* modals */}
    </div>
  );
};

export default UpdateView;
