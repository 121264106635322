import React from "react";
import { useNavigate, Routes, Route, useNavigation } from "react-router-dom";
////Access Controle Middle Wares
import {
  CheckUserModuleAccess,
  CheckModuleCreateAccess,
  CheckModuleEditAccess,
  CheckModuleDeleteAccess,
  CheckAdminAccess,
} from "../../middleware/authorizationMiddleware";
//
import { sessionStorage } from "es-storage";
//pages
import Dashboard from "./Dashboard/Dashboard";
import Quotation from "./Quotation/Quotations";
//

const Sales = () => {
  //
  const navigate = useNavigate();
  //

  //
  return (
    <div className="moduleMainDiv">
      <div className="moduleHeadervDiv">
        <div className="moduleNameDiv">
          <h2
            className="moduleName"
            onClick={() => {
              navigate("./");
            }}
            style={{
              cursor: "pointer",
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Sales
          </h2>
        </div>
      </div>

      <div className="moduleMngActionDiv">
        <div className="moduleMngActionBtns">
          <button
            onClick={() => {
              navigate(".");
            }}
          >
            Dashboard
          </button>

          <button
            onClick={() => {
              navigate(".");
            }}
          >
            Products
          </button>

          <button
            onClick={() => {
              navigate("quotation");
            }}
          >
            Quotations
          </button>

          <button
            onClick={() => {
              navigate(".");
            }}
          >
            Invoice
          </button>

          <button
            onClick={() => {
              navigate(".");
            }}
          >
            configurations
          </button>
        </div>
      </div>

      <div className="moduleDisplay">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="/quotation/*" element={<Quotation />} />
          {/* <Route path="/nonfixed" element={<NONFIXED />} />
          <Route path="/fixed" element={<FIXED />} />
          <Route path="/warehousemng" element={<WarehouseMng />} />
          <Route path="/productcategory" element={<ProductCategorie />} />
          <Route path="/productsubcategory" element={<ProductSubCategorie />} />
          <Route path="/producttransfer" element={<Transfer />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default Sales;
