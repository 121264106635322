//### Core
import React, { useEffect, useState } from "react";

//### Third Party
//reach text editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
//ant design
import { Divider, Tooltip } from "antd";
import { Spin, Select } from "antd";
import { Button } from "antd";
import { Tabs as AntTabs } from "antd";
import { Form, Input, Space } from "antd";
// ant design icons
import { BoldOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
//### Absolute
//notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../../Components/Notifications/Notifications";
//api methods
import { addCheckOut } from "../../../../API/Inventory/checkin_checkout/checkin_checkout_api";
import { getAllUser } from "../../../../API/User/userAPI";
import { getAllProduct } from "../../../../API/Inventory/product/productAPI";

//###interfaces
// interface default_type {
//   id: Number;
//   name: String;
// }
//

const CreateView = () => {
  //fields
  const [to, setTo] = useState("");
  const [reference, setReference] = useState("");
  const [description, setDescription] = useState("");
  const [checkout_date, setCheckout_Date] = useState("");
  const [checkin_date, setCheckin_Date] = useState("");
  const [requestedBy, setRequestedBy] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [productLine, setProductLine] = useState<
    Array<{ productID: String; quantity: Number; uom: String; remark: String }>
  >([]);
  //default
  const [recordNote, setRecordNote] = useState("");
  const [createdByName, setCreatedByName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [updatedByName, setUpdatedByName] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  //dataStates
  const [usersData, setUsersData] = useState([]);
  const [productsData, setProductsData] = useState([]);

  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);

  //### Methods
  //default methods
  const getProductList = async () => {
    try {
      const res = await getAllProduct();
      setProductsData(res.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };
  const getUsersList = async () => {
    try {
      const res = await getAllUser();
      setUsersData(res.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };
  //
  useEffect(() => {
    getUsersList();
    getProductList();
  }, []);

  //### CRUD Methods
  const handleAddModelRecord = async (event: any) => {
    event.preventDefault();

    try {
      //
      setLoading(true);
      //
      var req = await addCheckOut({
        To: to,
        Reference: reference,
        Description: description,
        Checkout_Date: checkout_date,
        Checkin_Date: checkin_date,
        ProductLine: productLine,
        RequestedBy: requestedBy,
        ApprovedBy:approvedBy,
        ///
        Record_Note: recordNote,
      });
      notifySuccess(req.data);
      //Reset Form
    } catch (error: any) {
      handleCustomError(error);
    } finally {
      //
      setLoading(false);
      //
    }
  };

  return (
    <div>
      <form className="ModuleCreateViewForm" onSubmit={handleAddModelRecord}>
        <Spin spinning={loading}>
          <div className="form-container">
            <div className="form-header">
              <h4>New Checkout Form</h4>

              <Button
                type="primary"
                htmlType="submit"
                icon={<CloudUploadOutlined />}
              >
                Save
              </Button>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Enter product category name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  To:
                </Tooltip>
                <input
                  type="text"
                  placeholder=""
                  onChange={(event) => setTo(event.target.value)}
                  required
                />
              </label>
              <label>
                <Tooltip
                  title="Enter product category name"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Ref:
                </Tooltip>
                <input
                  type="text"
                  placeholder="010101"
                  onChange={(event) => setReference(event.target.value)}
                  required
                />
              </label>
            </div>
            <div className="form-row">
              <label>
                <Tooltip
                  title="Planned return date"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Checked Out Date:
                </Tooltip>
                <Input
                  type="date"
                  value={checkout_date}
                  onChange={(e) => setCheckout_Date(e.target.value)}
                ></Input>
              </label>
              <label>
                <Tooltip
                  title="Planned return date"
                  color="#333333"
                  placement="bottomLeft"
                >
                  Checked In Date:
                </Tooltip>
                <Input
                  type="date"
                  value={checkin_date}
                  onChange={(e) => setCheckin_Date(e.target.value)}
                ></Input>
              </label>
            </div>

            <div className="form-row">
              <AntTabs
                defaultActiveKey="1"
                items={[
                  {
                    key: "1",
                    label: "Product Lines",
                    children: (
                      <>
                        <Form
                          name="dynamic_form_nest_item"
                          style={{ maxWidth: 1000 }}
                          autoComplete="off"
                          onValuesChange={(changedValues, allValues) => {
                            if (allValues.attributes) {
                              setProductLine(allValues.attributes);
                              console.log(
                                "Updated lines:",
                                allValues.attributes
                              );
                            }
                          }}
                          initialValues={{
                            attributes: productLine,
                          }}
                        >
                          <Form.List name="attributes">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Space
                                    key={key}
                                    style={{
                                      display: "flex",
                                      marginBottom: 8,
                                    }}
                                    align="baseline"
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, "productID"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Add Product",
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        placeholder="Select a Product"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        // onChange={(value) =>
                                        //   setProductUOMID(value)
                                        // }
                                        options={productsData.map((p: any) => ({
                                          value: p.id,
                                          label: p.name,
                                        }))}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "quantity"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input
                                        type="Number"
                                        placeholder="Quantity"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "uom"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="UOM" readOnly />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "remark"]}
                                      rules={[
                                        {
                                          // required: fa,
                                          // message: "Missing attribute value",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Remark" />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Line
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </Form>
                      </>
                    ),
                  },
                  {
                    key: "2",
                    label: "Requester",
                    children: (
                      <>
                        <div className="form-row">
                          <label>
                            <Tooltip
                              title="Enter product category name"
                              color="#333333"
                              placement="bottomLeft"
                            >
                              Requested By:
                            </Tooltip>
                            <Select
                              showSearch
                              placeholder="Select User"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={(value) => setRequestedBy(value)}
                              options={usersData.map((u: any) => ({
                                value: u.id,
                                label: u.fullName,
                              }))}
                            />
                          </label>
                        </div>
                      </>
                    ),
                  },
                  {
                    key: "3",
                    label: "Approved",
                    children: (
                      <>
                        <div className="form-row">
                          <label>
                            <Tooltip
                              title="Enter product category name"
                              color="#333333"
                              placement="bottomLeft"
                            >
                              Approved By:
                            </Tooltip>
                            <Select
                              showSearch
                              placeholder="Select User"
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={(value) => setApprovedBy(value)}
                              options={usersData.map((u: any) => ({
                                value: u.id,
                                label: u.fullName,
                              }))}
                            />
                          </label>
                        </div>
                      </>
                    ),
                  },
                ]}
              ></AntTabs>
            </div>
            <Divider />
            <div className="form-row">
              <label>Description:</label>
              <textarea
                placeholder="Enter Product Category Description"
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
            <ReactQuill
              theme="snow"
              placeholder="Add any relevant notes about this record"
              onChange={setRecordNote}
            />
          </div>
        </Spin>
      </form>
    </div>
  );
};

export default CreateView;
