import React from "react";
import axios from "axios";
import { sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");
const current_user = sessionStorage.get("user_name");
const current_user_id = sessionStorage.get("user_id");

const addUOM_Category = async (props: any) => {
  try {
    var req = await axios.post(
      `${process.env.REACT_APP_UOM_CATEGORIE_ADD_ONE}`,
      {
        name: props.Name,
        recordNote: props.Record_Note,
        createdByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getOneUOM_Categories = async (ID: String) => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_UOM_CATEGORIE_GET_ONE}/${ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const getAllUOM_Categories = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_UOM_CATEGORIE_GET_ALL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const updateSingeleUOM_Category = async (props: any) => {
  try {
    var req = await axios.put(
      `${process.env.REACT_APP_UOM_CATEGORY_UPDATE_ONE}/${props.ID}`,
      {
        name: props.Name,
        recordNote: props.Record_Note,
        updatedByID: current_user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

const deleteSingeleUOMCategory = async (ID: String | Number) => {
  try {
    var req = await axios.delete(
      `${process.env.REACT_APP_UOM_CATEGORY_DELETE_ONE}/${ID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return req;
  } catch (err: any) {
    throw err;
  }
};

export {
  addUOM_Category,
  getOneUOM_Categories,
  getAllUOM_Categories,
  updateSingeleUOM_Category,
  deleteSingeleUOMCategory,
};
