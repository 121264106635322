import axios from "axios";
import { localStorage, sessionStorage } from "es-storage";
const token = sessionStorage.get("HS_token");

const getAllInventoryStatistics = async () => {
  try {
    var req = await axios.get(
      `${process.env.REACT_APP_STAT_BASE}/inventory_stat`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(req);
    return req;
  } catch (err: any) {
    throw err;
  }
};

export { getAllInventoryStatistics };
