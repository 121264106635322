//### Core
import React, { useEffect, useState } from "react";

//### Third Parts
import { useNavigate } from "react-router-dom";
//ant design
import { Empty, Spin } from "antd";
import { Modal as AntdModal } from "antd";
//material ui
import Box from "@mui/material/Box";
//mui icons
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
//mui grid table
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from "@mui/x-data-grid";
//

//### Absolute
// notification
import {
  notifyError,
  notifySuccess,
  handleCustomError,
} from "../../../../../Components/Notifications/Notifications";
//

//file imports
import {
  getAllUOM_Categories,
  deleteSingeleUOMCategory,
} from "../../../../../API/Inventory/uom/uom_category_api";

const ListView = () => {
  //### Varibles
  const navigate = useNavigate();
  //#data states
  const [listViewDate, setListViewDate] = useState([]);
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //

  //### Methods
  //default methods
  const GetListViewData = async () => {
    try {
      var req: any = await getAllUOM_Categories();
      console.log(req.data);
      setListViewDate(req.data);
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        notifyError(error.response.data);
        notifyError(error.response.data.message);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        // console.log(error.request);
        notifyError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error

        // console.log("Error", error.message);
        notifyError(error.message);
      }
    }
  };

  useEffect(() => {
    GetListViewData();
  }, []);
  //

  //###Constraints and onchanges

  //###CRUD Methods

  //###Action Methods
  const handleDeleteClick = async (id: any) => {
    //setRows(rows.filter((row) => row.id !== id));
    //alert("delete");
    try {
      const call = await deleteSingeleUOMCategory(id);
      console.log(call);
      GetListViewData();
      notifySuccess(call.data);
    } catch (error: any) {
      handleCustomError(error);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      // editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      // editable: true,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 150,
      // editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(`update`, { state: { routeRecordId: id } })}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            //onClick={handleDeleteClick(id)}
            onClick={() => {
              AntdModal.confirm({
                title: "Confirm",
                content: "Are you sure you want to delete this record?",
                footer: (_, { OkBtn, CancelBtn }) => (
                  <>
                    {/* <Button>Custom Button</Button> */}
                    <CancelBtn />
                    <OkBtn />
                  </>
                ),
                onOk: () => {
                  handleDeleteClick(id);
                },
              });
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <div>
      <Spin spinning={loading}>
        {listViewDate && listViewDate.length > 0 ? (
          <DataGrid
            rows={listViewDate}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  // createdAt: false,
                  // createdBy: false,
                  // updatedAt: false,
                  // updatedBy: false,
                },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default ListView;
